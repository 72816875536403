import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { SuspensionJustification } from '@models/suspension-justification';

import { environment } from 'src/environments/environment';
import { AuthSessionService } from './auth-session.service';
import { FirebaseAbstract } from './firebase.abstract';

class SuspensionJustificationFactory extends FirebaseAbstract<SuspensionJustification> {
  constructor(protected db: AngularFirestore) {
    super(
      db,
      `/projects/${environment.project}/customers/${AuthSessionService.user.projectAccess[0].customer}/suspension-justifications`
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class SuspensionJustificationService {
  constructor(protected db: AngularFirestore) { }

  factory() {
    return new SuspensionJustificationFactory(this.db);
  }

  add(data: SuspensionJustification) {
    return this.factory().add(data);
  }

  update(data: SuspensionJustification) {
    return this.factory().update(data);
  }

  save(data: SuspensionJustification) {
    return this.factory().save(data);
  }

  set(data: SuspensionJustification) {
    return this.factory().set(data);
  }

  setActivate(id: string, value: boolean) {
    return this.factory().update({ id, active: value });
  }

  getById(id: string) {
    return this.factory().getById(id);
  }

  public async getByPatientId(patientId: string) {
    return this.factory().getWhere('patientId', '==', patientId, 'createdAt', 'desc');
  }

  getAll() {
    return this.factory().getAll('createdAt');
  }
}
