<div class="layout">
  <!-- Sidebar -->
  <app-sidebar></app-sidebar>

  <!-- Content -->
  <main class="layout-content">
    <app-topbar></app-topbar>
    <router-outlet></router-outlet>
  </main>
</div>
