import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { AuthSessionService } from '@services/auth-session.service';
import { EventParentToChildService } from '@services/event-parent-to-child.service';
import { LicenseControlService } from '@services/license-control.service';

import { UserType } from '../enums/user-type';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private _licenseControl: LicenseControlService,
    private _session: AuthSessionService,
    private _emitEvents: EventParentToChildService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return this.guard(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return this.guard(route, state);
  }

  private async guard(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const user = this._session.getLoggedUser();

    if (!user) {
      return this.router.parseUrl(`/login?returnUrl=${state.url}`);
    }

    // Verificar se o Administrador precisa continuar cadastro
    if (user?.type !== UserType.MASTER) {
      if (!user?.acceptUseTerms) {
        return this.router.parseUrl('/user-accept-use-terms');
      }

      if (user?.type !== UserType.ADMINISTRATOR) {
        if (user?.password) {
          return this.router.parseUrl('/user-first-access');
        }
      }

      // ----- step 1 -----
      if (!user?.projectAccess || (user.projectAccess && !user.projectAccess.length)) {
        return this.router.navigate(['/register'], { state: { data: user } });
      }

      if (user?.type === UserType.ADMINISTRATOR) {
        // ----- step 2 -----
        const licenseList = await this._licenseControl.getAll();
        if (!licenseList.length) {
          return this.router.navigate(['/register'], { state: { data: user, step: 2 } });
        } else {
          if (state.url === '/home') {
            return this.router.parseUrl('/dashboard');
          } else {
            return true;
          }
        }
        // ----- step 3 -----
        // const paymentMethods = await this._payment.getAll();
        // if (!paymentMethods.length) {
        //   return this.router.navigate(['/register'], { state: { data: logged, step: 3 } });
        // }
      }
      // Se for Terapeuta, vai para a agenda dele
      // if (
      //   (user?.type === UserType.THERAPIST && (state.url === '/' || state.url === '/home')) ||
      //   state.url.includes('dashboard')
      // ) {
      //   return this.router.parseUrl('/home');
      //   // Se for Paciente, vai para a agenda dele
      // } else
      if (
        user?.type === UserType.PATIENT &&
        (state.url === '/' || state.url === '/home' || state.url.includes('dashboard'))
      ) {
        setTimeout(() => {
          this._emitEvents.broadcast({ name: 'emitFromGuard' });
        }, 1000);
        return this.router.parseUrl('/patient/patient-environment');
      }
    }

    if (user?.type === UserType.THERAPIST) {
      if (state.url === 'dashboard') {
        return true;
      } else if (state.url === 'home') {
        this.router.parseUrl('/dashboard');
      }
    }

    if (state.url === '/home') {
      return this.router.parseUrl('/dashboard');
    }
    return true;
  }
}
