import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { JobTitle } from '@models/job-title';

import { environment } from 'src/environments/environment';
import { FirebaseAbstract } from './firebase.abstract';

@Injectable({
  providedIn: 'root'
})
export class JobTitleService extends FirebaseAbstract<JobTitle> {
  constructor(protected db: AngularFirestore) {
    super(db, `/projects/${environment.project}/job-titles`);
  }

  save(data: JobTitle) {
    if (data.name) {
      data.name = data.name.toLowerCase();
    }
    return super.save(data);
  }

  update(data: Partial<JobTitle>) {
    if (data.name) {
      data.name = data.name.toLowerCase();
    }
    return super.update(data);
  }

  getAllActive() {
    return super.getWhere('active', '==', true);
  }
}
