import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { IReturnPagination } from '@interfaces/ireturn-pagination';
import { IUserItem } from '@interfaces/iuser-item';
import { User } from '@models/user';
import { environment } from 'src/environments/environment';

import { FirebaseAbstract } from './firebase.abstract';

@Injectable({
  providedIn: 'root'
})
export class UserService extends FirebaseAbstract<User> {
  constructor(protected db: AngularFirestore, private _http: HttpClient) {
    super(db, 'users');
  }

  public create(user: User) {
    user.name = user.name.toLowerCase();
    return this._http.post(`${environment.apiUrl}/users`, user).toPromise();
  }

  public createPassword(user) {
    user.name = user.name.toLowerCase();
    return this._http.post(`${environment.apiUrl}/users/password`, user).toPromise();
  }

  public async existEmail(email: string): Promise<boolean> {
    const doc = await super.getWhere('email', '==', email);
    return doc?.length > 0;
  }

  public async existCpf(cpf: string): Promise<boolean> {
    const doc = await super.getWhereMany([
      { field: 'cpf', operator: '==', value: cpf },
      { field: 'active', operator: '==', value: true }
    ]);
    return !!doc?.length;
  }

  public getList(page: number, search?: string): Promise<IReturnPagination<IUserItem>> {
    return this._http
      .get<IReturnPagination<IUserItem>>(
        `/users/master/administrators?page=${page}${search ? `&search=${search}` : ''}`
      )
      .toPromise();
  }

  public getLicenseList(
    customer: string,
    licenseId: string,
    page: number,
    search?: string
  ): Promise<IReturnPagination<IUserItem>> {
    return this._http
      .get<IReturnPagination<IUserItem>>(
        `/users/${environment.project}/${customer}/${licenseId}?page=${page}${search ? `&search=${search}` : ''}`
      )
      .toPromise();
  }

  public setActivate(id: string, value: boolean) {
    return super.update({ id, active: value });
  }

  public async setAllLead() {
    const list = await super.getAll();
    const updateList: Promise<void>[] = [];
    list.forEach(x => {
      if (!x.lead) {
        updateList.push(super.update({ id: x.id, lead: false }));
      }
    });
    return Promise.all(updateList);
  }

  setPatientCancelRequested(id: string) {
    return this.collection().doc(id).update({ id, cancelRequested: true, cancelRequestedDate: this.timestamp });
  }

  async isCpfActive(cpf: string): Promise<boolean> {
    const docs = await this.getWhereMany([
      { field: 'cpf', operator: '==', value: cpf },
      { field: 'active', operator: '==', value: true }
    ]);
    return !!docs?.length;
  }
}
