import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthSessionService } from '@services/auth-session.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private _session: AuthSessionService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    if (request.url.startsWith(environment.apiUrl)) {
      return from(this._session.getBearerToken().catch(() => null)).pipe(
        switchMap(token => {
          if (token) {
            const requestClone = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`
              }
            });

            return next.handle(requestClone);
          }

          return next.handle(request);
        })
      );
    }

    return next.handle(request);
  }
}
