export enum UserType {
  COMMON = 'common',
  ADMINISTRATOR = 'administrator',
  MASTER = 'master',
  DEVELOPER = 'developer',
  PATIENT = 'patient',
  THERAPIST = 'therapist'
}

export const UserTypeLabel = new Map<string, string>([
  [UserType.COMMON, 'Comum'],
  [UserType.ADMINISTRATOR, 'Administrador'],
  [UserType.MASTER, 'Master'],
  [UserType.DEVELOPER, 'Desenvolvedor'],
  [UserType.PATIENT, 'Paciente'],
  [UserType.THERAPIST, 'Terapeuta']
]);
