import { IPagCredCardTokenResponse, IPagPlanInterface, IPagSubscriptions } from '@interfaces/ipag';
import { Patient } from '@models/patient';
import * as moment from 'moment';

export function transformName(name: string) {
  if (!(name || '').length) {
    return '';
  }

  const nameSprited = name.toLowerCase().split(' ');
  const last = nameSprited.length > 1 ? nameSprited[nameSprited.length - 1] : '';
  return (
    nameSprited[0].charAt(0).toUpperCase() +
    nameSprited[0].slice(1) +
    (nameSprited.length > 1 ? ' ' + last.substr(0, 1).toUpperCase() + '.' : '')
  );
}

export function getAge(date: string): number {
  if (!date) {
    return 0;
  }
  const year = Number(date.substr(6, 4));
  const month = Number(date.substr(3, 2));
  const day = Number(date.substr(0, 2));
  const actualYear = new Date().getFullYear();

  const diff = new Date();
  diff.setMonth(month - 1, day);
  const doBirth = new Date().getTime() - diff.getTime() < 0 ? 1 : 0;

  return actualYear - year - doBirth;
}

export function getMonth(date: string): number {
  if (!date) {
    return 0;
  }

  const month = Number(date.substring(3, 5)) - 1;
  const actualMonth = new Date().getMonth();

  const diff = actualMonth - month;

  return diff > 0 ? diff : diff + 12;
}

export function getAgeText(date: string) {
  const age = getAge(date);
  if (age > 0) {
    return `${age} ano${age > 1 ? 's' : ''}`;
  } else {
    const months = getMonth(date);
    const compl = months === 1 ? 'mês' : 'meses';
    return `${months} ${compl}`;
  }
}

export function incompleteRegister(patient: Patient) {
  if (
    !!!patient ||
    !!!patient.name ||
    !!!patient.email ||
    !!!patient.dateOfBirth ||
    !!!patient.gender ||
    !!!patient.cpf ||
    !!!patient.responsible?.fathersName ||
    !!!patient.responsible?.mothersName
  ) {
    return true;
  }

  return false;
}

export function notCpfSaved(patient: Patient) {
  if (!!!patient?.cpf) {
    return true;
  }

  return false;
}

export function formatToISOString(date: string, hour?: string) {
  return `${date.split('/')[2]}-${date.split('/')[1]}-${date.split('/')[0]}T${hour ? hour : '00:00:00.000Z'}`;
}

export function formatToDate(date: string) {
  return `${date.split('/')[2]}-${date.split('/')[1]}-${date.split('/')[0]}`;
}

export function generateIdSubitem() {
  return `${(new Date().getTime() + Math.floor(Math.random() * 9999999999999)).toString(16)}`;
}

export function getNameAndLastSurname(name: string) {
  if (name) {
    const splitName = name.split(' ');
    return splitName.length > 1 ? `${splitName[0]} ${splitName[splitName.length - 1]}` : name;
  }
  return '';
}

export function isSecretary(jobTitleId: string) {
  return ['SnqdAz2PhqGCt69gxJKI'].includes(jobTitleId);
}

export function isIntern(jobTitleId: string) {
  return ['x5YjRTbLysmRXS5VVSnT'].includes(jobTitleId);
}

export function isManagerClinic(jobTitleId: string) {
  return ['5dSOERN35QKD3JUz2Hee'].includes(jobTitleId);
}

export function isAdministrative(jobTitleId: string) {
  return ['pKAVyDxjC16JKvB1SENb'].includes(jobTitleId);
}

export function isAbstenceColorInterval(value: string) {
  if (value && value.length <= 7) {
    const limitRed = 230;
    const color = value.includes('#') ? value.replace('#', '') : value;
    const R = color.substring(0, 2);
    const G = color.substring(2, 4);
    const B = color.substring(4, 6);
    if (parseInt(R, 16) >= limitRed) {
      const green = parseInt(G, 16);
      const blue = parseInt(B, 16);
      return green <= 65 && blue <= 65 && Math.abs(green - blue) <= 20;
    }
    return false;
  }
  return false;
}

export function IPagCredCardTokenAttrToDate(data: IPagCredCardTokenResponse) {
  if (data) {
    if (data.attributes.validated_at && typeof data.attributes.validated_at === 'string') {
      data.attributes.validated_at = moment(data.attributes.validated_at, 'YYYY-MM-DD HH:mm:ss').toDate();
    }
    if (data.attributes.expires_at && typeof data.attributes.expires_at === 'string') {
      data.attributes.expires_at = moment(data.attributes.expires_at, 'YYYY-MM-DD HH:mm:ss').toDate();
    }
    if (data.attributes.created_at && typeof data.attributes.created_at === 'string') {
      data.attributes.created_at = moment(data.attributes.created_at, 'YYYY-MM-DD HH:mm:ss').toDate();
    }
    if (data.attributes.updated_at && typeof data.attributes.updated_at === 'string') {
      data.attributes.updated_at = moment(data.attributes.updated_at, 'YYYY-MM-DD HH:mm:ss').toDate();
    }
  }
}

export function IPagPlanInterfaceAttrToDate(data: IPagPlanInterface) {
  if (data) {
    if (data.attributes.created_at && typeof data.attributes.created_at === 'string') {
      data.attributes.created_at = moment(data.attributes.created_at, 'YYYY-MM-DD HH:mm:ss').toDate();
    }
    if (data.attributes.updated_at && typeof data.attributes.updated_at === 'string') {
      data.attributes.updated_at = moment(data.attributes.updated_at, 'YYYY-MM-DD HH:mm:ss').toDate();
    }
  }
}

export function iPagSubscriptionAttrToDate(data: IPagSubscriptions) {
  IPagCredCardTokenAttrToDate(data.attributes.creditcard);
  IPagPlanInterfaceAttrToDate(data.attributes.plan);
  if (data) {
    if (data.attributes.starting_date && typeof data.attributes.starting_date === 'string') {
      data.attributes.starting_date = moment(data.attributes.starting_date, 'YYYY-MM-DD').startOf('day').toDate();
    }
    if (data.attributes.created_at && typeof data.attributes.created_at === 'string') {
      data.attributes.created_at = moment(data.attributes.created_at, 'YYYY-MM-DD HH:mm:ss').toDate();
    }
    if (data.attributes.updated_at && typeof data.attributes.updated_at === 'string') {
      data.attributes.updated_at = moment(data.attributes.updated_at, 'YYYY-MM-DD HH:mm:ss').toDate();
    }
  }
}

/*

private _user: UserService,
private _anamnese: AnamneseService,
private _evaluation: EvaluationService,
private _treatmentPlan: TreatmentPlanService,
private _trainingSetupNeuroStimulus: TrainingSetupNeuroStimulusService,

async deleteUsers() {
    const ids = ['LwfvwNE3KBdzKucGSbNHiCGOSHH2'];

    //patient-leads
    const deleteLeads = ids.map(id => this._lead.factory().delete(id));

    //schedule
    const schedulesDelete = await this._schedule
      .factory()
      .getWhere('patientId', 'in', ids)
      .then(docs => {
        return docs.map(s => this._schedule.deleteById(s.id));
      });

    //banked-hours
    const bankedDelete = await this._bankedHours
      .factory()
      .getWhere('patientId', 'in', ids)
      .then(docs => {
        return docs.map(data => this._bankedHours.factory().delete(data.id));
      });

    //anamnese
    const anamneseDelete = await this._anamnese
      .factory()
      .getWhere('patientId', 'in', ids)
      .then(docs => {
        return docs.map(data => this._anamnese.factory().delete(data.id));
      });

    //evaluation
    const evaluationDelete = await this._evaluation
      .factory()
      .getWhere('patientId', 'in', ids)
      .then(docs => {
        return docs.map(data => this._evaluation.factory().delete(data.id));
      });

    //treatment-plans
    const treatmentPlanDelete = await this._treatmentPlan
      .factory()
      .getWhere('patientId', 'in', ids)
      .then(docs => {
        return docs.map(data => this._treatmentPlan.factory().delete(data.id));
      });

    //trainig-setups
    const trainingSetupDelete = await this._trainingSetup
      .factory()
      .getWhere('patientId', 'in', ids)
      .then(docs => {
        return docs.map(data => this._trainingSetup.factory().delete(data.id));
      });

    //trainig-setup-neuro-stimulus
    const trainingSetupNeuroStimulusDelete = await this._trainingSetupNeuroStimulus
      .factory()
      .getWhere('patientId', 'in', ids)
      .then(docs => {
        return docs.map(data => this._trainingSetupNeuroStimulus.factory().delete(data.id));
      });

    //treatment-cycles
    const deleteCycles: Promise<void>[] = [];
    for (const userId of ids) {
      try {
        const cycles = await this._cycle.Patient(userId).getAll();
        if (cycles?.length) {
          cycles.forEach(c => {
            deleteCycles.push(this._cycle.Patient(userId).delete(c.id));
          });
        }
      } catch (error) {}
    }

    //user
    const deleteUsers = ids.map(id => this._user.delete(id));

    try {
      const resLead = await Promise.all(deleteLeads);
      console.log('resLead ->', resLead);
    } catch (error) {
      console.error(error);
    }
    try {
      const resSchedule = await Promise.all(schedulesDelete);
      console.log('resSchedule ->', resSchedule);
    } catch (error) {
      console.error(error);
    }
    try {
      const resBancked = await Promise.all(bankedDelete);
      console.log('resBancked ->', resBancked);
    } catch (error) {
      console.error(error);
    }
    try {
      const resAnamnese = await Promise.all(anamneseDelete);
      console.log('resAnamnese ->', resAnamnese);
    } catch (error) {
      console.error(error);
    }
    try {
      const resEvaluation = await Promise.all(evaluationDelete);
      console.log('resEvaluation ->', resEvaluation);
    } catch (error) {
      console.error(error);
    }
    try {
      const resTreatmentPlan = await Promise.all(treatmentPlanDelete);
      console.log('resTreatmentPlan ->', resTreatmentPlan);
    } catch (error) {
      console.error(error);
    }
    try {
      const resTrainingSetup = await Promise.all(trainingSetupDelete);
      console.log('resTrainingSetup ->', resTrainingSetup);
    } catch (error) {
      console.error(error);
    }
    try {
      const resTrainingSetupNeuroStimulus = await Promise.all(trainingSetupNeuroStimulusDelete);
      console.log('resTrainingSetupNeuroStimulus ->', resTrainingSetupNeuroStimulus);
    } catch (error) {
      console.error(error);
    }
    try {
      const resCycles = await Promise.all(deleteCycles);
      console.log('resCycles ->', resCycles);
    } catch (error) {
      console.error(error);
    }
    try {
      const resUsers = await Promise.all(deleteUsers);
      console.log('resUsers ->', resUsers);
    } catch (error) {
      console.error(error);
    }
    // await Promise.all(liceseUserControlsDelete);
  }
*/
