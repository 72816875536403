import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstWordCapitalize'
})
export class FirstWordCapitalizePipe implements PipeTransform {
  transform(name: string): string {
    if (!(name || '').length) {
      return '';
    }

    let capitalize = `${name[0].toUpperCase()}${name.substr(1)}`;

    return capitalize;
  }
}
