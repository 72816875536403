<div class="modal-header">
  <h4 class="modal-title">Justificativa de Suspensão</h4>
  <button type="button" class="close" aria-label="Fechar modal" (click)="modalClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="formGroup" (ngSubmit)="onSubmit()" novalidate>
  <div class="modal-body">
    <input-form type="textarea" [rows]="3" name="justification" label="Digite a justificativa" placeholder=" "
      [submitted]="submitted" [formGroup]="formGroup"></input-form>
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary btn-block rounded-pill waves-ligh" [disabled]="submitting">
      <span class="spinner-grow spinner-grow-sm mr-1" *ngIf="submitting"></span>
      Salvar
    </button>

    <button type="button" class="btn btn-link" (click)="modalClose()">Fechar</button>
  </div>
</form>
