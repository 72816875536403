import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@helpers/storage';
import { User } from '@models/user';
import { AuthSessionService } from '@services/auth-session.service';
import { CustomerService } from '@services/customer.service';
import { EventService } from '@services/event.service';
import { EventParentToChildService } from '@services/event-parent-to-child.service';
import { isAdministrative, isIntern, isManagerClinic, isSecretary } from '@utils/uteis';
import { UserType } from 'src/app/core/enums/user-type';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  collapsed = false;
  colapseSm = false;
  userCurrent: User;
  UserType = UserType;
  customerName = '';
  isSecretary = isSecretary;
  isIntern = isIntern;
  isManagerClinic = isManagerClinic;
  isAdministrative = isAdministrative;

  constructor(
    private _event: EventService,
    private router: Router,
    private _session: AuthSessionService,
    private _customer: CustomerService,
    private _eventSidebar: EventParentToChildService
  ) {
    this.userCurrent = AuthSessionService.user;
  }

  ngOnInit() {
    if (!this.userCurrent) {
      this.router.navigate(['/login']);
    }
    this._eventSidebar.subscrible('emitFromGuard', () => {
      this.colapseSm = this.collapsed = true;
      this.switchNav();
    });
    this.collapsed = Storage.getItem('collapsed') || false;
    this.getCustomerName();
  }

  async getCustomerName() {
    const customerId = this.userCurrent?.projectAccess[0] ? this.userCurrent?.projectAccess[0].customer : '';
    if (!!this.userCurrent?.projectAccess[0]) {
      const custom = await this._customer.getById(customerId);
      this.customerName = custom.name ?? '';
    }
  }

  switchNav() {
    this.collapsed = !this.collapsed;
    Storage.setItem('collapsed', this.collapsed);

    this._event.publish('menu');
  }

  logoutUser() {
    this._session.logout();
    this.router.navigate(['login']);
  }
}
