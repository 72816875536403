<div class="container-fluid fisio-navbar">
  <div *ngIf="showTitle" class="d-flex align-items-center">
    <h2 class="fisio-navbar-title">{{ title }}</h2>
    <span class="fisio-navbar-subtitle ml-2">{{ subtitle }}</span>
  </div>
  <div class="d-sm-flex d-md-none">
    <li class="dropdown notification-list list-unstyled" ngbDropdown>
      <a
        class="nav-link dropdown-toggle waves-effect waves-light notification-icon"
        href="javascript: void(0);"
        role="button"
        aria-haspopup="false"
        aria-expanded="false"
        ngbDropdownToggle
        id="notificationDropdown"
      >
        <i class="fas fa-bell"></i>
        <span class="badge badge-danger rounded-circle noti-icon-badge" *ngIf="notificationItems.length">
          {{ notificationItems.length }}
        </span>
        <!-- <span class="badge" *ngIf="notificationItems.length"></span> -->
      </a>

      <div
        class="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-width cursor-pointer"
        aria-labelledby="notificationDropdown"
        ngbDropdownMenu
      >
        <div class="dropdown-item noti-title" ngbDropdownItem>
          <h5 class="m-0">
            <span class="float-right cursor-pointer">
              <a (click)="clearAllNotificationItems()" class="text-dark">
                <small>Limpar todas</small>
              </a>
            </span>
            Notificações
          </h5>
        </div>

        <div id="notification-items" class="noti-scroll">
          <a
            *ngFor="let notification of notificationItems"
            (click)="clickNotificationItem(notification)"
            class="dropdown-item notify-item"
            ngbDropdownItem
          >
            <div
              *ngIf="!notification.chatId"
              class="notify-icon bg-soft-{{ notification.bgColor }} text-{{ notification.bgColor }}"
            >
              <i class="{{ notification.icon }}"></i>
            </div>
            <div *ngIf="notification.chatId" class="notify-icon">
              <img
                src="https://ui-avatars.com/api/?name={{
                  notification.userName | firstNameAndLastAbbreviated
                }}&background=351186&color=fff"
                alt="Imagem do usuário"
                class="user-avatar-notify"
              />
            </div>
            <p class="notify-details">
              {{ notification.text }}
              <small class="text-muted">{{ notification.subText }}</small>
            </p>
          </a>
        </div>
      </div>
    </li>
  </div>
  <ul class="list-unstyled d-none d-md-flex">
    <span class="user-type">Versão {{ version }}</span>
    <li class="dropdown notification-list" ngbDropdown>
      <a
        class="nav-link dropdown-toggle waves-effect waves-light notification-icon"
        href="javascript: void(0);"
        role="button"
        aria-haspopup="false"
        aria-expanded="false"
        ngbDropdownToggle
        id="notificationDropdown"
      >
        <i class="fas fa-bell"></i>
        <span class="badge badge-danger rounded-circle noti-icon-badge" *ngIf="notificationItems.length">
          {{ notificationItems.length }}
        </span>
        <!-- <span class="badge" *ngIf="notificationItems.length"></span> -->
      </a>

      <div
        class="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-width cursor-pointer"
        aria-labelledby="notificationDropdown"
        ngbDropdownMenu
      >
        <div class="dropdown-item noti-title" ngbDropdownItem>
          <h5 class="m-0">
            <span class="float-right cursor-pointer">
              <a (click)="clearAllNotificationItems()" class="text-dark">
                <small>Limpar todas</small>
              </a>
            </span>
            Notificações
          </h5>
        </div>

        <div id="notification-items" class="noti-scroll">
          <a
            *ngFor="let notification of notificationItems"
            (click)="clickNotificationItem(notification)"
            class="dropdown-item notify-item"
            ngbDropdownItem
          >
            <div
              *ngIf="!notification.chatId"
              class="notify-icon bg-soft-{{ notification.bgColor }} text-{{ notification.bgColor }}"
            >
              <i class="{{ notification.icon }}"></i>
            </div>
            <div *ngIf="notification.chatId" class="notify-icon">
              <img
                src="https://ui-avatars.com/api/?name={{
                  notification.userName | firstNameAndLastAbbreviated
                }}&background=351186&color=fff"
                alt="Imagem do usuário"
                class="user-avatar-notify"
              />
            </div>
            <p class="notify-details">
              {{ notification.text }}
              <small class="text-muted">{{ notification.subText }}</small>
            </p>
          </a>
        </div>
      </div>
    </li>

    <li class="dropdown notification-list border-divider" ngbDropdown>
      <a
        class="nav-link dropdown-toggle waves-effect waves-light user-info"
        ngbDropdownToggle
        id="profileDropdown"
        href="javascript: void(0);"
        role="button"
        aria-haspopup="false"
        aria-expanded="false"
      >
        <span class="user-name"
          >{{ user?.name | firstNameAndLastAbbreviated }} <br />
          <span class="user-type" *ngIf="!user?.jobTitleId">{{ user?.type | translateNameTypePipe }}</span>
          <span class="user-type" *ngIf="jobTitle">{{ jobTitle.name | customCapitalize }}</span>
        </span>

        <img
          src="https://ui-avatars.com/api/?name={{
            user?.name | firstNameAndLastAbbreviated
          }}&background=351186&color=fff"
          alt="Imagem do usuário"
          class="user-avatar"
        />
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-dropdown" aria-labelledby="profileDropdown" ngbDropdownMenu>
        <div class="dropdown-header noti-title">
          <h6 class="text-overflow m-0">Bem Vindo !</h6>
        </div>

        <div class="dropdown-divider"></div>

        <a class="dropdown-item notify-item cursor-pointer" (click)="logout()">
          <i class="fe-log-out"></i>
          Sair
        </a>
      </div>
    </li>
  </ul>
</div>
