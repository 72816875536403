import { NgbDatepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ColorPickerModule } from 'ngx-color-picker';
import { CommonModule } from '@angular/common';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';

import { InputFormComponent } from './input-form.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    NgbTooltipModule,
    NgSelectModule,
    UiSwitchModule,
    ColorPickerModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [InputFormComponent],
  exports: [InputFormComponent]
})
export class InputFormModule {}
