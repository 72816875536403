import { Pipe, PipeTransform } from '@angular/core';
import { formatDistance } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

@Pipe({
  name: 'timeDistance'
})
export class TimeDistancePipe implements PipeTransform {
  transform(value: Date, addSuffix: boolean = true): string {
    if (typeof value === 'string') {
      value = new Date(value);
    }
    return formatDistance(value, new Date(), { locale: ptBR, addSuffix });
  }
}
