import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class FireStorageService {
  constructor(private storage: AngularFireStorage) {}

  public putString(base64Data: string, path: string): Promise<string> {
    const type = base64Data.split(';')[0].split('/')[1];
    const name = `${uuidv4()}.${type}`;
    const fileRef = this.storage.ref(`/imagens/${environment.project}/${path}/${name}`);
    const task = fileRef.putString(base64Data, 'data_url');
    return task.then(snapshot => snapshot.ref.getDownloadURL());
  }

  public updatePutStringFromURL(url, imagedata): Promise<string> {
    const task = this.storage.storage.refFromURL(url).putString(imagedata, 'data_url');
    return task.then(snapshot => snapshot.ref.getDownloadURL());
  }

  public deleteFromURL(url) {
    return this.storage.storage.refFromURL(url).delete();
  }

  public readFileAsDataURL(file): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };
      fileReader.onerror = err => {
        reject(err);
      };
      fileReader.readAsDataURL(file);
    });
  }
}
