<nav
  class="sidebar-nav"
  [class.md-sidebar-collapsed]="collapsed"
  [class.sm-sidebar-collapsed]="colapseSm"
  (click)="colapseSm = !colapseSm"
>
  <div class="sidebar-logo">
    <img src="assets/images/Logo.png" alt="" (click)="switchNav()" class="md-logo" />
    <img src="assets/images/Logo.png" alt="" class="sm-logo" />

    <img class="icon-close" src="assets/images/colapse-sidenav.svg" alt="" [hidden]="colapseSm" />
    <img class="icon-close" src="assets/images/close-colapsed-sidenav.svg.svg" alt="" [hidden]="!colapseSm" />

    <em>Menu</em>
  </div>

  <div class="nav-groups-container" *ngIf="userCurrent?.type === UserType.ADMINISTRATOR">
    <ul>
      <li>
        <a class="sidebar-nav-link" routerLink="/dashboard" routerLinkActive="active">
          <i class="fas fa-chart-pie"></i><em>Visão Geral</em>
        </a>
      </li>
      <li>
        <a class="sidebar-nav-link" routerLink="/admin/my-clinic" routerLinkActive="active">
          <i class="fas fa-clinic-medical"></i><em>Meus Dados</em>
        </a>
      </li>
      <li>
        <a class="sidebar-nav-link" routerLink="/license/view" routerLinkActive="active">
          <i class="fas fa-id-card"></i><em>Licenças</em>
        </a>
      </li>
      <li>
        <a class="sidebar-nav-link" routerLink="/payments" routerLinkActive="active">
          <i class="fas fa-credit-card"></i><em>Pagamentos</em>
        </a>
      </li>
      <li>
        <a class="sidebar-nav-link" routerLink="/invoice" routerLinkActive="active">
          <i class="fas fa-file-invoice-dollar"></i><em>Faturas</em>
        </a>
      </li>
      <li>
        <a class="sidebar-nav-link" routerLink="/user" routerLinkActive="active">
          <i class="fas fa-users"></i><em>Usuários</em>
        </a>
      </li>
    </ul>

    <hr />

    <ul>
      <li>
        <a class="sidebar-nav-link" routerLink="/schedule/calendar" routerLinkActive="active">
          <i class="fas fa-calendar-alt"></i><em>Agenda</em>
        </a>
      </li>
      <li>
        <a class="sidebar-nav-link" routerLink="/banked-hours" routerLinkActive="active">
          <i class="fas fa-clock"></i><em>Banco de horas</em>
        </a>
      </li>
      <li>
        <a class="sidebar-nav-link" routerLink="/patient-lead" routerLinkActive="active">
          <i class="fas fa-user-edit"></i><em>Leads</em>
        </a>
      </li>
      <li>
        <a class="sidebar-nav-link" routerLink="/tutorials/view"
          ><i class="fas fa-play-circle"></i><em>Tutoriais</em></a
        >
      </li>
      <li>
        <a class="sidebar-nav-link" routerLink="/chat-user" routerLinkActive="active" routerLinkActive="active">
          <i class="fas fa-comments"></i><em>Chat</em>
        </a>
      </li>
      <li>
        <a class="sidebar-nav-link" routerLink="/settings"> <i class="fas fa-cog"></i><em>Configurações</em> </a>
      </li>
    </ul>
  </div>

  <div class="nav-groups-container" *ngIf="userCurrent?.type === UserType.MASTER">
    <ul>
      <li>
        <a class="sidebar-nav-link" routerLink="/dashboard" routerLinkActive="active">
          <i class="fas fa-chart-pie"></i><em>Visão Geral</em>
        </a>
      </li>
      <li>
        <a class="sidebar-nav-link" routerLink="/license/list" routerLinkActive="active">
          <i class="fas fa-id-card"></i><em>Licenças</em>
        </a>
      </li>
      <li>
        <a class="sidebar-nav-link" routerLink="/master/job-title" routerLinkActive="active">
          <i class="fas fa-id-badge"></i><em>Cargos</em>
        </a>
      </li>
    </ul>

    <hr />

    <ul>
      <li>
        <a class="sidebar-nav-link" routerLink="/master/user" routerLinkActive="active">
          <i class="fas fa-users"></i><em>Clientes</em>
        </a>
      </li>
      <li>
        <a class="sidebar-nav-link" routerLink="/master/invoice" routerLinkActive="active">
          <i class="fas fa-file-invoice-dollar"></i><em>Faturas</em>
        </a>
      </li>
      <li>
        <a class="sidebar-nav-link" routerLink="/settings" routerLinkActive="active">
          <i class="fas fa-cog"></i><em>Configurações</em>
        </a>
      </li>
    </ul>
  </div>

  <div class="nav-groups-container" *ngIf="userCurrent?.type === UserType.THERAPIST">
    <!-- TERAPEUTA COMUM -->
    <ng-template [ngIf]="userCurrent.jobTitleId !== 'AsZ203J9yS9SOS0KbBoe'">
      <ul>
        <li>
          <a class="sidebar-nav-link" routerLink="/dashboard" routerLinkActive="active">
            <i class="fas fa-chart-bar"></i><em>Dashboard</em>
          </a>
        </li>
        <li>
          <a class="sidebar-nav-link" routerLink="/schedule/therapist-calendar" routerLinkActive="active">
            <i class="fas fa-calendar-alt"></i><em>Agenda</em>
          </a>
        </li>
        <li>
          <a class="sidebar-nav-link" routerLink="/evaluation" routerLinkActive="active">
            <i class="fas fa-file-alt"></i><em>Avaliação</em>
          </a>
        </li>
        <li>
          <a
            class="sidebar-nav-link"
            routerLink="/patient/list"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ match: ['/patient/managing'] }"
          >
            <i class="fas fa-user-circle"></i><em>Pacientes</em>
          </a>
        </li>
        <!-- <li>
          <a class="sidebar-nav-link" routerLink="/treatment/fisio/" routerLinkActive="active">
            <i class="fas fa-eye"></i><em>Fisio Inteligente Teste</em>
          </a>
        </li> -->

        <!-- <li>
          <a class="sidebar-nav-link" routerLink="/patient/cycle" routerLinkActive="active">
            <i class="fas fa-sync-alt"></i><em>Ciclo de tratamento</em>
          </a>
        </li> -->
        <li>
          <a class="sidebar-nav-link" routerLink="/patient/training/do-training" routerLinkActive="active">
            <i class="fas fa-dumbbell"></i><em>Executar treino</em>
          </a>
        </li>
        <!-- <li>
          <a class="sidebar-nav-link" routerLinkActive="active">
            <i class="fas fa-chart-line"></i><em>Evolução</em>
          </a>
        </li> -->
        <li>
          <a class="sidebar-nav-link" routerLink="/tutorials/view" routerLinkActive="active">
            <i class="fas fa-play-circle"></i><em>Tutorial</em>
          </a>
        </li>
        <!-- <li>
          <a class="sidebar-nav-link" routerLinkActive="active">
            <i class="fas fa-user-md"></i><em>Meu Cadastro</em>
          </a>
        </li> -->
        <li>
          <a class="sidebar-nav-link" routerLink="/chat-user" routerLinkActive="active"
            ><i class="fas fa-comments"></i><em>Chat</em></a
          >
        </li>
      </ul>
    </ng-template>
    <!-- GESTOR TERAPÊUTICO -->
    <ng-template [ngIf]="userCurrent.jobTitleId === 'AsZ203J9yS9SOS0KbBoe'">
      <ul>
        <li>
          <a class="sidebar-nav-link" routerLink="/schedule/calendar" routerLinkActive="active">
            <i class="fas fa-calendar-alt"></i><em>Agenda</em>
          </a>
        </li>
        <li>
          <a class="sidebar-nav-link" routerLink="/banked-hours" routerLinkActive="active">
            <i class="fas fa-clock"></i><em>Banco de horas</em>
          </a>
        </li>
        <li>
          <a class="sidebar-nav-link" routerLink="/patient/list" routerLinkActive="active">
            <i class="fas fa-user-circle"></i><em>Pacientes</em>
          </a>
        </li>
        <li>
          <a class="sidebar-nav-link" routerLinkActive="active"> <i class="fas fa-file-alt"></i><em>Avaliação</em> </a>
        </li>
        <li>
          <a class="sidebar-nav-link" routerLink="/patient-lead" routerLinkActive="active">
            <i class="fas fa-user-edit"></i><em>Leads</em>
          </a>
        </li>
        <li>
          <a class="sidebar-nav-link" routerLink="/tutorials/view"
            ><i class="fas fa-play-circle"></i><em>Tutoriais</em></a
          >
        </li>
        <li>
          <a class="sidebar-nav-link" routerLink="/settings"> <i class="fas fa-cog"></i><em>Configurações</em> </a>
        </li>
      </ul>
    </ng-template>
  </div>

  <div class="nav-groups-container" *ngIf="userCurrent?.type === UserType.COMMON">
    <!-- SECRETÁRIA ou ESTAGIÁRIO -->
    <ng-template [ngIf]="isSecretary(userCurrent.jobTitleId) || userCurrent.jobTitleId === 'x5YjRTbLysmRXS5VVSnT'">
      <ul>
        <li>
          <a class="sidebar-nav-link" routerLink="/schedule/calendar" routerLinkActive="active">
            <i class="fas fa-calendar-alt"></i><em>Agenda</em>
          </a>
        </li>
        <ng-template [ngIf]="isSecretary(userCurrent.jobTitleId)">
          <li>
            <a class="sidebar-nav-link" routerLink="/dashboard" routerLinkActive="active">
              <i class="fas fa-chart-pie"></i><em>Visão Geral</em>
            </a>
          </li>
        </ng-template>
        <li>
          <a class="sidebar-nav-link" routerLink="/patient" routerLinkActive="active">
            <i class="fas fa-user-circle"></i><em>Pacientes</em>
          </a>
        </li>
        <li>
          <a class="sidebar-nav-link" routerLink="/patient-lead" routerLinkActive="active">
            <i class="fas fa-user-edit"></i><em>Leads</em>
          </a>
        </li>
        <li>
          <a class="sidebar-nav-link" routerLink="/chat-user" routerLinkActive="active"
            ><i class="fas fa-comments"></i><em>Chat</em></a
          >
        </li>
      </ul>
    </ng-template>

    <!-- GESTOR DE CLÍNICA ou ADMINISTRATIVO -->
    <ng-template [ngIf]="isManagerClinic(userCurrent.jobTitleId) || isAdministrative(userCurrent.jobTitleId)">
      <ul>
        <li>
          <a class="sidebar-nav-link" routerLink="/dashboard" routerLinkActive="active">
            <i class="fas fa-chart-pie"></i><em>Visão Geral</em>
          </a>
        </li>
        <li>
          <a class="sidebar-nav-link" routerLink="/admin/my-clinic" routerLinkActive="active">
            <i class="fas fa-clinic-medical"></i><em>Meus Dados</em>
          </a>
        </li>
        <!-- <li>
          <a class="sidebar-nav-link" routerLink="/license/view" routerLinkActive="active">
            <i class="fas fa-id-card"></i><em>Licenças</em>
          </a>
        </li> -->
        <li>
          <a class="sidebar-nav-link" routerLink="/payments" routerLinkActive="active">
            <i class="fas fa-credit-card"></i><em>Pagamentos</em>
          </a>
        </li>
        <li>
          <a class="sidebar-nav-link" routerLink="/user" routerLinkActive="active">
            <i class="fas fa-users"></i><em>Usuários</em>
          </a>
        </li>
      </ul>

      <hr />

      <ul>
        <li>
          <a class="sidebar-nav-link" routerLink="/schedule/calendar" routerLinkActive="active">
            <i class="fas fa-calendar-alt"></i><em>Agenda</em>
          </a>
        </li>
        <li>
          <a class="sidebar-nav-link" routerLink="/banked-hours" routerLinkActive="active">
            <i class="fas fa-clock"></i><em>Banco de horas</em>
          </a>
        </li>
        <li>
          <a class="sidebar-nav-link" routerLink="/patient/list" routerLinkActive="active">
            <i class="fas fa-user-circle"></i><em>Pacientes</em>
          </a>
        </li>
        <li>
          <a class="sidebar-nav-link" routerLink="/patient-lead" routerLinkActive="active">
            <i class="fas fa-user-edit"></i><em>Leads</em>
          </a>
        </li>
        <li>
          <a class="sidebar-nav-link" routerLink="/tutorials/view"
            ><i class="fas fa-play-circle"></i><em>Tutoriais</em></a
          >
        </li>
        <li>
          <a class="sidebar-nav-link" routerLink="/settings"> <i class="fas fa-cog"></i><em>Configurações</em> </a>
        </li>
      </ul>
    </ng-template>
  </div>

  <div class="nav-groups-container" *ngIf="userCurrent?.type === UserType.PATIENT">
    <ul>
      <li>
        <a class="sidebar-nav-link" routerLink="/evolution/patient-feedback" routerLinkActive="active">
          <i class="fas fa-file-audio"></i><em>Devolutiva</em>
        </a>
      </li>
      <li>
        <a class="sidebar-nav-link" routerLink="/patient/activities-home" routerLinkActive="active">
          <i class="fas fa-running"></i><em>Atividades</em>
        </a>
      </li>
      <li>
        <a class="sidebar-nav-link" routerLink="/schedule/patient-calendar" routerLinkActive="active">
          <i class="fas fa-calendar-alt"></i><em>Agenda</em>
        </a>
      </li>
      <li>
        <a class="sidebar-nav-link" routerLink="/patient/patient-environment" routerLinkActive="active">
          <i class="fas fa-brain"></i><em>Ambiente</em>
        </a>
      </li>
      <li>
        <a class="sidebar-nav-link" routerLink="/patient/account" routerLinkActive="active">
          <i class="fas fa-user-edit"></i><em>Cadastro</em>
        </a>
      </li>
      <li>
        <a class="sidebar-nav-link" routerLink="/chat-user" routerLinkActive="active" routerLinkActive="active">
          <i class="fas fa-comments"></i><em>Chat</em>
        </a>
      </li>
    </ul>
  </div>

  <div class="mt-0">
    <hr class="mt-0" />
    <div class="customer d-flex justify-content-center px-3">
      {{ customerName ? customerName : userCurrent?.type === UserType.MASTER ? 'Acesso Master' : '' }}
    </div>
    <hr />
    <ul class="mt-0 pt-0">
      <li class="mt-0 pt-0">
        <a class="sidebar-nav-link mt-0" (click)="logoutUser()"> <i class="fas fa-door-open"></i><em>Sair</em> </a>
      </li>
    </ul>
  </div>

  <div class="collapse-button" (click)="switchNav()">
    <i class="fe-arrow-left" *ngIf="!collapsed"></i>
    <i class="fe-arrow-right" *ngIf="collapsed"></i>
  </div>
</nav>
