import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { AuthSessionService } from '@services/auth-session.service';

@Injectable({
  providedIn: 'root'
})
export class NotAuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private _session: AuthSessionService) {}

  canActivate(): boolean | UrlTree {
    return this.guard();
  }

  canActivateChild(): boolean | UrlTree {
    return this.guard();
  }

  private guard(): boolean | UrlTree {
    const user = this._session.getLoggedUser();

    if (user) {
      return this.router.parseUrl('/home');
    }

    return true;
  }
}
