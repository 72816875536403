import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Chat, ChatMessage, ReadPart } from '@models/chat';
import { AuthSessionService } from '@services/auth-session.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { ChatService } from './chat.service';
import { FirebaseAbstract, IDocumentObservable } from './firebase.abstract';

interface IResponseMessages {
  lastDocVisible: any;
  docs: IDocumentObservable<ChatMessage>[];
}

interface IResponseMoreMessages {
  lastDocVisible: any;
  docs: ChatMessage[];
}

class ChatMessageFactory extends FirebaseAbstract<ChatMessage> {
  constructor(protected chatId: string, protected db: AngularFirestore, private _chat: ChatService) {
    super(
      db,
      `/projects/${environment.project}/customers/${AuthSessionService.user.projectAccess[0].customer}/chats/${chatId}/messages`
    );
  }

  private participantsRead(participants: string[]) {
    const readPart: ReadPart[] = [];
    for (const id of participants) {
      readPart.push({ id: id, isRead: false });
    }
    return readPart;
  }

  public async addMessage(text: string, participants: string[]) {
    const senderId = AuthSessionService.user.id;
    const batch = this.db.firestore.batch();

    const chatMessage: ChatMessage = {
      active: true,
      text,
      userId: senderId,
      type: 'text',
      isRead: this.participantsRead(participants),
      createdAt: null,
      updatedAt: null
    };

    const textRef = this.collection().doc();
    await batch.set(textRef, { ...chatMessage, createdAt: this.timestamp });
    chatMessage.id = textRef.id;
    delete chatMessage.isRead;
    const chatRef = this._chat.factory().collection().doc(this.chatId);
    await batch.update(chatRef, { id: this.chatId, lastMessage: { ...chatMessage, createdAt: this.timestamp } });
    await batch.commit();
    return textRef;
    // return super.add(chatMessage);
  }

  public async addAudio(audio: string, participants: string[]) {
    const senderId = AuthSessionService.user.id;
    const batch = this.db.firestore.batch();

    const chatMessage: ChatMessage = {
      active: true,
      audio,
      text: null,
      userId: senderId,
      type: 'audio',
      isRead: this.participantsRead(participants),
      createdAt: null,
      updatedAt: null
    };

    const audioRef = this.collection().doc();
    batch.set(audioRef, { ...chatMessage, createdAt: this.timestamp });
    chatMessage.id = audioRef.id;
    delete chatMessage.isRead;
    const chatRef = this._chat.factory().collection().doc(this.chatId);
    batch.update(chatRef, { id: this.chatId, lastMessage: { ...chatMessage, createdAt: this.timestamp } });
    await batch.commit();
    return audioRef;
  }

  getAllNotRead(userId: string) {
    return super.getWhereMany([{ field: 'isRead', operator: 'array-contains', value: { id: userId, isRead: false } }]);
  }

  getAsyncAllNotRead(userId: string) {
    return super.getAsyncWhereMany([
      { field: 'isRead', operator: 'array-contains', value: { id: userId, isRead: false } }
    ]);
  }

  setIsReadByList(list: ChatMessage[]) {
    const updates: Promise<void>[] = [];
    if (list?.length) {
      for (const m of list) {
        const index = m.isRead.findIndex(x => x.id === AuthSessionService.user.id);
        if (index >= 0) {
          m.isRead[index].isRead = true;
          updates.push(super.update({ id: m.id, isRead: m.isRead }));
        }
      }
    }
    return Promise.all(updates);
  }

  getMessages(): Observable<IResponseMessages> {
    return this.db
      .collection<ChatMessage>(this.collectionName, ref => ref.orderBy('createdAt', 'desc').limit(10))
      .stateChanges()
      .pipe(
        map(data => ({
          lastDocVisible: data[data?.length ? data.length - 1 : 0]?.payload?.doc,
          docs: data
            .map(({ type, payload: { doc, newIndex, oldIndex } }) => ({
              type,
              newIndex,
              oldIndex,
              data: this.toObject(doc)
            }))
            .reverse()
        }))
      );
  }

  async getMoreMessages(startAfter): Promise<IResponseMoreMessages> {
    const { docs, size } = await this.collection().orderBy('createdAt', 'desc').startAfter(startAfter).limit(10).get();

    if (size === 0) {
      return null;
    }

    return {
      lastDocVisible: docs[docs.length - 1],
      docs: docs.map(doc => this.toObject(doc))
    };
  }
}

class ChatMessageExternalFactory extends FirebaseAbstract<ChatMessage> {
  constructor(protected customer: string, protected chatId: string, protected db: AngularFirestore) {
    super(db, `/projects/${environment.project}/customers/${customer}/chats/${chatId}/messages`);
  }

  public async setAllIsRead(chat: Chat) {
    const updates: Promise<void>[] = [];
    const list = await this.getAll();
    for (const msg of list) {
      const reads = chat.participants
        .filter(p => p !== msg.userId)
        .map(id => {
          return { id: id, isRead: msg.isRead };
        });
      updates.push(this.collection().doc(msg.id).update({ isRead: reads }));
    }
    return Promise.all(updates);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ChatMessageService {
  constructor(protected db: AngularFirestore, private _chat: ChatService) {}

  Chat(id: string) {
    return new ChatMessageFactory(id, this.db, this._chat);
  }

  CustomerChat(customer: string, chatId: string) {
    return new ChatMessageExternalFactory(customer, chatId, this.db);
  }

  public async setAllIsRead() {
    const customers = ['2XlFkP2jOjkRfM8iGNnV', 'qP2ZuMLDMN2nSrNDyLyZ'];
    const updates: Promise<any>[] = [];

    for (const customer of customers) {
      try {
        const list = await this._chat.externalFactory(customer).getAll();
        if (list?.length) {
          for (const chat of list) {
            updates.push(this.CustomerChat(customer, chat.id).setAllIsRead(chat));
          }
        }
      } catch (error) {}
    }

    return Promise.all(updates);
  }
}
