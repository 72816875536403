import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { DocumentNotFoundError } from '@exceptions/document-not-found-error';
import { IPatientItem } from '@interfaces/ipatient-item';
import { IReturnPagination } from '@interfaces/ireturn-pagination';
import { Patient } from '@models/patient';
import { User } from '@models/user';
import { environment } from 'src/environments/environment';

import { UserType } from '../enums/user-type';
import { AuthSessionService } from './auth-session.service';
import { FireStorageService } from './fire-storage.service';
import { FirebaseAbstract } from './firebase.abstract';
import { UserService } from './user.service';

export interface IResponsePatient {
  success: boolean;
  user: {
    id: string;
  };
}

export interface IResponseMessagePatient {
  success: boolean;
  message: string;
}

class PatientFactory extends FirebaseAbstract<Patient> {
  constructor(
    protected db: AngularFirestore,
    private _http: HttpClient,
    private _fileStorage: FireStorageService,
    customer?: string
  ) {
    super(
      db,
      `/projects/${environment.project}/customers/${
        customer ? customer : AuthSessionService.user.projectAccess[0].customer
      }/users`
    );
  }

  public create(patient: Patient) {
    return this._http.post('/patients', patient).toPromise();
  }

  public preCreate(patient: Patient) {
    return this._http.post<IResponsePatient>('/patients/pre', patient).toPromise();
  }

  public uploadAvatar(avatarBase64: string) {
    return this._fileStorage.putString(avatarBase64, 'users');
  }

  public async getAll(): Promise<Patient[]> {
    return super.getWhere('type', '==', UserType.PATIENT);
  }

  public getAsyncAll() {
    return super.getAsyncWhereMany([{ field: 'type', operator: '==', value: UserType.PATIENT }]);
  }

  public getAsyncAllActive() {
    return super.getAsyncWhereMany([
      { field: 'type', operator: '==', value: UserType.PATIENT },
      { field: 'active', operator: '==', value: true },
      { field: 'suspended', operator: '==', value: false }
    ]);
  }

  public async getById(id: string): Promise<Patient> {
    try {
      const patient = await super.getById(id);

      if (patient.type !== UserType.PATIENT) {
        throw new DocumentNotFoundError(id);
      }

      return patient;
    } catch (e) {
      //
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class PatientService {
  constructor(
    protected db: AngularFirestore,
    private _http: HttpClient,
    private _fileStorage: FireStorageService,
    private _user: UserService
  ) {}

  public factory(customer?: string) {
    return new PatientFactory(this.db, this._http, this._fileStorage, customer);
  }

  public create(patient: Patient) {
    patient.name = patient.name.toLocaleLowerCase();
    if (patient.responsible && patient.responsible.fathersName)
      patient.responsible.fathersName = patient.responsible.fathersName.toLocaleLowerCase();
    if (patient.responsible && patient.responsible.fathersName)
      patient.responsible.mothersName = patient.responsible.mothersName.toLocaleLowerCase();
    return this.factory().create(patient);
  }

  public save(patient: Patient) {
    patient.name = patient.name.toLowerCase();
    return this.factory().save(patient);
  }

  public preCreate(patient: Patient) {
    patient.name = patient.name.toLowerCase();
    return this.factory().preCreate(patient);
  }

  public update(patient: Partial<Patient>) {
    if (patient.name) {
      patient.name = patient.name.toLowerCase();
    }
    return this._user.update(patient);
  }

  public uploadAvatar(avatarBase64: string): Promise<string> {
    return this.factory().uploadAvatar(avatarBase64);
  }

  public async getList(page: number, search?: string, licenseId?: string): Promise<IReturnPagination<IPatientItem>> {
    return this._http
      .get<IReturnPagination<IPatientItem>>(
        `/patients/${environment.project}/${AuthSessionService.user.projectAccess[0].customer}?page=${page}${
          search ? `&search=${search}` : ''
        }${licenseId ? `&license=${licenseId}` : ''}`
      )
      .toPromise();
  }

  public async sendEmailCancelLicense(patient: Patient, admin: User): Promise<IResponseMessagePatient> {
    return this._http
      .post<IResponseMessagePatient>('/patients/email-cancel-license', { patient: patient, admin: admin })
      .toPromise();
  }

  public async getAll() {
    return this.factory().getAll();
  }

  public getAllActives() {
    return this.factory().getWhereMany([
      { field: 'active', operator: '==', value: true },
      { field: 'type', operator: '==', value: UserType.PATIENT }
    ]);
  }

  public getAsyncAll() {
    return this.factory().getAsyncAll();
  }

  public getAsyncAllActive() {
    return this.factory().getAsyncAllActive();
  }

  public async getById(id: string): Promise<Patient> {
    return this.factory().getById(id);
  }

  public getAsyncById(id: string) {
    return this.factory().getAsyncById(id);
  }

  public async getByIdExist(id: string): Promise<Patient> {
    return this.factory().getByIdExist(id);
  }

  public setActivate(id: string, value: boolean) {
    return this._user.update({ id, active: value });
  }
}
