import { Activities } from './activities';
import { BaseModel } from './base-model';
import { Record } from './evaluation';

export class TrainingSetupNeuroStimulus extends BaseModel {
  patientId = '';
  therapistId = '';
  scheduledFirstTherapistId?: string = '';
  creatorTherapistId = '';
  therapeuticSpecialtyId = '';
  diagnosis = '';
  scheduleId = '';
  specialtyId = '';
  treatmentCycleId = '';
  treatmentPlanId = '';

  activity = new Activities();
  preparation: Preparation[] = [];
  simulation: Simulation[] = [];
  transfer: Transfer[] = [];
  record: Record[] = [];

  startSession: Date;

  observation = '';
  executed = false;
  executedDate: Date = null;
  finishedStep: number;
  finishedStepDate: Date = null;
}

export class Preparation {
  deficit = '';
  trainable_skill_id = '';
  muscle_structure_id = '';
  submuscle_structure_id = '';
  location = '';
  time = 0;
  checked = false;
}

export class Activity {
  name = '';
  time = 0;
  checked = false;
}

export class Simulation {
  activity: Activity = new Activity();
}

export class Transfer {
  activity = new Activity();
}
