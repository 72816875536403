import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from '@models/user';
import { environment } from 'src/environments/environment';

import { UserType } from '../enums/user-type';
import { AuthSessionService } from './auth-session.service';
import { FirebaseAbstract } from './firebase.abstract';

class UserCustomerFactory extends FirebaseAbstract<User> {
  constructor(protected db: AngularFirestore) {
    super(db, `/projects/${environment.project}/customers/${AuthSessionService.user.projectAccess[0].customer}/users`);
  }

  getAllLicensed() {
    return super.getAsyncWhere('type', '!=', UserType.ADMINISTRATOR);
  }

  getByCPF(cpf: string) {
    return super.getWhere('cpf', '==', cpf);
  }

  getByEmail(email: string) {
    return super.getWhere('email', '==', email);
  }

  getByListId(list: string[]) {
    const query: Promise<User>[] = [];
    list.forEach(id => {
      query.push(super.getById(id));
    });
    return Promise.all(query);
  }

  async getFirstAdmin() {
    const docs = await this.getWhereManyOrderBy(
      [{ field: 'type', operator: '==', value: UserType.ADMINISTRATOR }],
      'createdAt',
      'asc'
    );
    return docs?.length ? docs[0] : null;
  }
}

class UserCustomerExternalFactory extends FirebaseAbstract<User> {
  constructor(customerId: string, protected db: AngularFirestore) {
    super(db, `/projects/${environment.project}/customers/${customerId}/users`);
  }

  getByCPF(cpf: string) {
    return super.getWhere('cpf', '==', cpf);
  }

  public getByListId(list: string[]) {
    const query: Promise<User>[] = [];
    list.forEach(id => {
      query.push(super.getById(id));
    });
    return Promise.all(query);
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserCustomerService {
  constructor(protected db: AngularFirestore) {}

  factory() {
    return new UserCustomerFactory(this.db);
  }

  externalFactory(customerId: string) {
    return new UserCustomerExternalFactory(customerId, this.db);
  }

  set(data: User) {
    return this.factory().set(data);
  }

  getById(id: string) {
    return this.factory().getById(id);
  }

  getByListId(list: string[]) {
    return this.factory().getByListId(list);
  }

  getAll() {
    return this.factory().getAll();
  }

  getAsyncAll() {
    return this.factory().getAsyncAll();
  }

  getFirstAdmin() {
    return this.factory().getFirstAdmin();
  }

  public async existCpf(cpf: string): Promise<boolean> {
    const doc = await this.factory().getWhereMany([
      { field: 'cpf', operator: '==', value: cpf },
      { field: 'active', operator: '==', value: true }
    ]);
    return !!doc?.length;
  }
}
