import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';

import { Customer } from '@models/customer';
import { environment } from 'src/environments/environment';
import { FirebaseAbstract } from './firebase.abstract';
import * as moment from 'moment';
import { IPagCredCardTokenResponse } from '@interfaces/ipag';

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends FirebaseAbstract<Customer> {
  constructor(protected db: AngularFirestore) {
    super(db, `/projects/${environment.project}/customers`);
  }

  getByCNPJ(cnpj: string) {
    return super.getWhere('CNPJ', '==', cnpj);
  }

  getWhere(field: string, operator: firebase.firestore.WhereFilterOp, value: string) {
    return super.getWhere(field, operator, value);
  }

  getByListId(list: string[]): Promise<Customer[]> {
    const query: Promise<Customer>[] = [];
    list.forEach(id => {
      query.push(super.getById(id));
    });
    return Promise.all(query);
  }

  setCredCardTokenIpag(id: string, cardToken: IPagCredCardTokenResponse) {
    return this.update({ id: id, cardToken: cardToken });
  }
}
