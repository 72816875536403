import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SuspensionJustification } from '@models/suspension-justification';
import { User } from '@models/user';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SuspensionJustificationService } from '@services/suspension-justification.service';
import Swal from 'sweetalert2';
import { PatientService } from '../../../../core/services/patient.service';
import { AuthSessionService } from '../../../../core/services/auth-session.service';

@Component({
  selector: 'app-patient-suspension-justification-create',
  templateUrl: './patient-suspension-justification-create.component.html',
  styleUrls: ['./patient-suspension-justification-create.component.scss']
})
export class PatientSuspensionJustificationCreateComponent implements OnInit {

  formGroup: FormGroup;
  submitted = false;
  submitting = false;
  data: SuspensionJustification;
  user = new User();

  constructor(
    private formBuilder: FormBuilder,
    private _suspension: SuspensionJustificationService,
    private _patient: PatientService,
    private _auth: AuthSessionService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      justification: [null, Validators.required]
    });

    this.user = this._auth.getLoggedUser();
  }

  async onSubmit() {
    this.submitted = true;
    if (this.formGroup.invalid) {
      return;
    }

    this.submitting = true;

    try {
      Object.assign(this.data, this.formGroup.value);
      await this._patient.update({ id: this.data.patientId, suspended: true });
      await this._suspension.save(this.data);
      Swal.fire('Sucesso!', 'Dados salvos com sucesso!', 'success');
      this.modalClose(true);

    } catch (err) {
      Swal.fire('Ops!', 'Houve um erro ao salvar os dados, tente novamente!', 'error');
    }

    this.submitting = false;
  }

  modalClose(result?: any) {
    this.activeModal.close(result);
  }

}
