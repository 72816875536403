import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventParentToChildService } from '@services/event-parent-to-child.service';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

declare var flatpickr;

@Component({
  // tslint:disable-next-line: component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'input-form',
  templateUrl: './input-form.component.html',
  styleUrls: ['./input-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputFormComponent implements OnInit, AfterViewInit {
  @Input() id = `id${uuidv4().split('-').join('')}`;
  @Input() name: string;
  @Input() label: string;
  @Input() class: string;
  @Input() style: string;
  @Input() mask: string = null;
  @Input() ex: string;
  @Input() formGroup: FormGroup;
  @Input() submitted: boolean;
  @Input() disabled = false;
  @Input() alignCenter = false;
  @Input() placeholder: string;
  @Input() defaultBoColor = '#dfdfdf';
  @Input() color = '#00b19d';
  @Input() icon = '';

  @Input() type = 'text';
  @Input() rows = 1;
  @Input() minValue = 0;
  @Input() min: string;
  @Input() max: string;
  @Input() value: number;
  @Input() list: string[] = [];
  @Input() selecteds: boolean[] = [];

  @Input() multiple = false;
  @Input() textUppercase = false;
  @Input() tag = false;
  @Input() resize = true;
  @Input() step = '0.1';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() items: any[];
  @Input() selectLabel = 'name';
  @Input() selectId = 'id';
  @Input() notFoundText = 'Itens não encontrados';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() addTag: boolean | ((term: string) => any | Promise<any>);
  @Input() addTagText = 'Adicionar item';
  @Input() tagValue = 'name';
  @Input() addSelect = false;
  @Input() appendTo: string = null;
  @Input() groupBy: string | Function = null;
  @Input() groupValue: string | Function = null;
  @Input() clearAllText = 'Limpar tudo';
  @Input() clearable = false;
  @Input() largeSelect = false;

  @Input() dateFormat = 'd/m/Y';
  @Input() altFormat = 'd/m/Y';

  @Input() autoHeight = false;

  @Input() defaultDate: string = null;
  @Input() minDate: string = null;
  @Input() maxDate: string = null;
  @Input() componentcolor = '#ffffff';
  @Input() colorPosition = 'bottom';
  @Input() reference: string;

  viewPasswd = false;
  datePickr = null;

  @Output() onfocusout = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() keyup = new EventEmitter();
  @Output() clear = new EventEmitter();

  currentDate = moment().format('YYYY-MM-DD');

  constructor(private emitterEvent: EventParentToChildService) {}

  ngOnInit() {
    this.id += this.name;
    if (this.type === 'daterange') {
      setTimeout(() => {
        this.datePickr = flatpickr('#' + this.id, {
          mode: 'range',
          minDate: this.minDate,
          maxDate: this.maxDate,
          altInput: true,
          altFormat: this.altFormat,
          locale: 'pt',
          dateFormat: this.dateFormat,
          disableMobile: true
        });
      }, 500);
      this.clearDate();
    }
    if (this.type === 'date') {
      setTimeout(() => {
        this.datePickr = flatpickr('#' + this.id, {
          mode: 'single',
          minDate: this.minDate,
          maxDate: this.maxDate,
          altInput: true,
          altFormat: this.altFormat,
          locale: 'pt',
          dateFormat: this.dateFormat,
          disableMobile: true
        });
      }, 500);
    }
    if (this.type === 'datetime') {
      if (this.defaultDate) {
        setTimeout(() => {
          this.datePickr = flatpickr('#' + this.id, {
            mode: 'time',
            enableTime: true,
            locale: 'pt',
            altInput: true,
            minDate: this.minDate,
            maxDate: this.maxDate,
            altFormat: 'H:i',
            dateFormat: 'H:i',
            defaultDate: this.defaultDate,
            disableMobile: true
          });
        }, 500);
        this.clearDate();
      } else {
        setTimeout(() => {
          this.datePickr = flatpickr('#' + this.id, {
            mode: 'time',
            enableTime: true,
            locale: 'pt',
            altInput: true,
            minDate: this.minDate,
            maxDate: this.maxDate,
            altFormat: 'H:i',
            dateFormat: 'H:i',
            disableMobile: true
          });
        }, 500);
      }
    }
  }

  clearDate() {
    this.emitterEvent.subscrible('clearDate', (data: { name: string; value: string }) => {
      if (data?.value && data.value === this.reference) {
        const calendar = flatpickr('#' + this.id, {
          mode: 'range',
          minDate: this.minDate,
          maxDate: this.maxDate,
          altInput: true,
          altFormat: this.altFormat,
          locale: 'pt',
          dateFormat: this.dateFormat,
          disableMobile: true
        });
        if (calendar) {
          try {
            calendar.clear();
          } catch (error) {
            console.error(error);
          }
        }
      }
    });
  }

  ngAfterViewInit() {
    if (this.type === 'select') {
      const select = document.getElementsByClassName('ng-select-container') as HTMLCollection;
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < select.length; i++) {
        select[i].setAttribute('style', 'background: #EBEBEB; color: #6c757d;');
      }
      const drop = document.getElementsByClassName('ng-arrow-wrapper') as HTMLCollection;
      for (let j = 0; j < select.length; j++) {
        drop[j].setAttribute(
          'style',
          'background: #351186; border-radius: 50%; height: 22px; width: 22px; margin-right: 6px; padding: 0;'
        );
      }
      const arrow = document.getElementsByClassName('ng-arrow') as HTMLCollection;
      for (let k = 0; k < select.length; k++) {
        arrow[k].setAttribute('style', 'border-color: #fff transparent transparent; top: 1px;');
      }
    }

    const item = document.getElementById(this.id) as HTMLInputElement;
    const thit = this;

    if (this.step && item && item.setAttribute) {
      item.setAttribute('step', this.step);
    }
    if (this.type === 'number') {
      item.min = this.min;
    }
    if (this.disabled) {
      this.f.disable();
    }

    if (this.change && item) {
      // tslint:disable-next-line: only-arrow-functions
      item.addEventListener('change', function (res) {
        thit.change.emit(res);
      });
    }

    if (this.onfocusout && item) {
      // tslint:disable-next-line: only-arrow-functions
      item.addEventListener('focusout', function (res) {
        // thit.emit('onfocusout');
        thit.onfocusout.emit(res);
      });
    }

    if (this.keyup && item) {
      // tslint:disable-next-line: only-arrow-functions
      item.addEventListener('keyup', function (res) {
        thit.keyup.emit(res);
      });
    }
  }

  openSelectList() {
    if (this.items && this.items[0] && this.items[0].bgcolor) {
      setTimeout(() => {
        const vet = document.getElementsByClassName('ng-dropdown-panel');
        if (vet && vet[0]) {
          const id = vet[0].id;
          for (let z = 0; z < this.items.length; z++) {
            const opt = document.getElementById(`${id}-${z}`);
            opt.style.backgroundColor = this.items[z].bgcolor;
            if (opt.classList.contains('ng-option-selected') || opt.classList.contains('ng-option-marked')) {
              opt.classList.remove('ng-option-selected');
              opt.classList.remove('ng-option-marked');
              opt.style.fontWeight = '700';
            }
          }
        }
      }, 50);
    }
  }

  textBlur(event) {
    if (!this.disabled && event.currentTarget.value && event.currentTarget.value.trim) {
      event.currentTarget.value = event.currentTarget.value.trim();
    }
  }

  gbClick(evt, cb?) {
    const selected = cb && typeof cb === 'string' ? evt.currentTarget.innerText : cb[this.selectId];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const val: any[] = this.f.value || [];
    if (this.multiple) {
      if (val && val.length) {
        const idx = val.findIndex(x => x === selected);
        if (idx < 0) {
          val.push(selected);
        } else {
          val.splice(idx, 1);
        }
        this.f.setValue(val);
      } else {
        this.f.setValue([selected]);
      }
    } else {
      if (val && val.length) {
        this.f.setValue(val[0] === selected || val === selected ? null : [selected]);
      } else {
        this.f.setValue([selected]);
      }
    }

    const thit = this;
    thit.emit('change', this.f.value);
  }

  isChecked(cb, value = []) {
    const ncb = cb && cb.trim ? cb.trim() : cb;
    if (value && value.find) {
      const find = value.find(x => x === (typeof ncb === 'string' ? ncb : ncb[this.selectId]));
      return !!find;
    }
    return ncb === value;
  }

  colorChange() {
    this.f.setValue(this.componentcolor);
  }

  addTagFn(name) {
    return { name, tag: true };
  }

  addCustomUser = term => {
    const aux = {};
    if (this.tag) {
      aux[this.selectId] = term;
      aux[this.selectLabel] = term;
    }
    return this.tag ? aux : null;
  };

  calcHeight(value) {
    const numberOfLineBreaks = (value.match(/\n/g) || []).length;
    // min-height + lines x line-height + padding + border
    const newHeight = 20 + numberOfLineBreaks * 20 + 12 + 2;
    return newHeight;
  }

  changeViewPasswd() {
    this.viewPasswd = !this.viewPasswd;
  }

  selectDate() {
    this.datePickr.open();
  }

  emit(item, evt = null) {
    if (this[item]) {
      if (this.type === 'select' || this.type === 'groupbox' || this.type === 'switch') {
        this[item].emit(evt);
      } else {
        this[item].emit();
      }
    }
  }

  get f() {
    return this.formGroup.controls[this.name];
  }
}
