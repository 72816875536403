export function lastNameAbbeviated(name: string) {
  const nameSprited = name.toLowerCase().trim().split(' ');
  const last = nameSprited.length > 1 ? `${nameSprited[nameSprited.length - 1].charAt(0).toUpperCase()}.` : '';

  return `${nameSprited[0].charAt(0).toUpperCase()}${nameSprited[0].slice(1)} ${last}`;
}
export function translateNameType(name: string) {
  switch (name) {
    case 'master':
      return 'Master';
    case 'common':
      return 'Administrativo';
    case 'patient':
      return 'Paciente';
    case 'therapist':
      return 'Terapeuta';
    case 'administrator':
      return 'Administrador';
    default:
      return '';
  }
}
