import { UserType } from '../enums/user-type';
import { BaseModel } from './base-model';
import { ProjectAccess } from './project-access';

export class User extends BaseModel {
  active = true;
  acceptUseTerms?: boolean;
  lead = false;
  intern = false;
  avatar = '';
  name = '';
  email = '';
  type = UserType.COMMON;
  jobTitleId = '';
  access = '';
  projectAccess: ProjectAccess[] = [];
  plan = '';
  cpf = '';
  cep = '';
  address = '';
  number = '';
  district = '';
  city = '';
  state = '';
  licenseId = '';
  dateOfBirth = '';
  admissionDate = '';
  resignationDate = '';
  maxHours = '';
  maxExtraHours = '';
  startHourAttendance?: string;
  endHourAttendance?: string;
  lastSignInTime?: Date = null;
  role = '';
  password?: string;
  age = 0;
}

export class UsersKey {
  [key: string]: User;
}
