import { NgModule } from '@angular/core';

import { CustomCapitalizePipe } from './custom-capitalize.pipe';
import { DateChatPipe } from './date-chat.pipe';
import { FirstNamePipe } from './first-name.pipe';
import { FirstNameAndLastAbbreviatedPipe } from './first-name-and-last-abbreviated.pipe';
import { FirstWordCapitalizePipe } from './first-word-capitalize.pipe';
import { TimeDistancePipe } from './time-distance.pipe';
import { TranslateNameTypePipe } from './translate-name-type.pipe';

@NgModule({
  declarations: [
    FirstNameAndLastAbbreviatedPipe,
    TranslateNameTypePipe,
    CustomCapitalizePipe,
    FirstWordCapitalizePipe,
    DateChatPipe,
    FirstNamePipe,
    TimeDistancePipe
  ],
  exports: [
    FirstNameAndLastAbbreviatedPipe,
    TranslateNameTypePipe,
    CustomCapitalizePipe,
    FirstWordCapitalizePipe,
    DateChatPipe,
    FirstNamePipe,
    TimeDistancePipe
  ]
})
export class PipeModule {}
