import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { AuthSessionService } from '@services/auth-session.service';

import { UserType } from '../enums/user-type';

@Injectable({
  providedIn: 'root'
})
export class AuthMasterAdminGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private _session: AuthSessionService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this.guard(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this.guard(route, state);
  }

  private guard(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const user = this._session.getLoggedUser();

    if (!user) {
      return this.router.parseUrl(`/login?returnUrl=${state.url}`);
    }

    if (user.type !== UserType.MASTER && user.type !== UserType.ADMINISTRATOR) {
      return this.router.parseUrl(`/`);
    }

    return true;
  }
}
