import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleTopBarService {
  private title = new Subject<string>();
  private subtitle = new Subject<string>();

  update(title: string) {
    this.title.next(title);
    this.updateSub(null);
  }

  updateSub(subtitle: string) {
    this.subtitle.next(subtitle);
  }

  get change() {
    return this.title;
  }

  get changeSub() {
    return this.subtitle;
  }
}
