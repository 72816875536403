import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCapitalize'
})
export class CustomCapitalizePipe implements PipeTransform {
  transform(name: string): string {
    if (!(name || '').length) {
      return '';
    }

    const nameSplit = name.toLowerCase().split(' ');
    let capitalize = '';
    nameSplit.forEach((text, i) => {
      if (text) {
        if (
          i > 0 &&
          (text === 'a' ||
            text === 'à' ||
            text === 'e' ||
            text === 'é' ||
            text === 'és' ||
            text === 'os' ||
            text === 'as' ||
            text === 'de' ||
            text === 'da' ||
            text === 'do' ||
            text === 'ao' ||
            text === 'aos' ||
            text === 'das' ||
            text === 'dos')
        ) {
          capitalize += ' ' + text;
        } else {
          capitalize += (i > 0 ? ' ' : '') + text.charAt(0).toUpperCase() + text.slice(1);
        }
      }
    });

    return capitalize;
  }
}
