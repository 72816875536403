import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { LicenseUserControl } from '@models/license-user-control';

import { environment } from 'src/environments/environment';
import { AuthSessionService } from './auth-session.service';
import { FirebaseAbstract } from './firebase.abstract';
import { License } from '@models/license';
import { UserCustomerService } from './user-customer.service';

class LicenseUserControlFactory extends FirebaseAbstract<LicenseUserControl> {
  constructor(public licenseControlId: string, protected db: AngularFirestore) {
    super(
      db,
      `/projects/${environment.project}/customers/${AuthSessionService.user.projectAccess[0].customer}/license-control/${licenseControlId}/license-users-control`
    );
  }

  getAllActives() {
    return super.getWhere('active', '==', true);
  }

  // public toObject(document: firebase.firestore.DocumentData): LicenseUserControl {
  //   const data = super.toObject(document);

  //   if (data.activationDates && data.activationDates.length) {
  //     data.activationDates.forEach(date => {
  //       date = date ? (date as any).toDate() : null;
  //     });
  //   }

  //   if (data.inactivationDates && data.inactivationDates.length) {
  //     data.inactivationDates.forEach(date => {
  //       date = date ? (date as any).toDate() : null;
  //     });
  //   }

  //   return data;
  // }
}

class LicenseUserControlCustomerFactory extends FirebaseAbstract<LicenseUserControl> {
  constructor(public customerId, public licenseControlId: string, protected db: AngularFirestore) {
    super(
      db,
      `/projects/${environment.project}/customers/${customerId}/license-control/${licenseControlId}/license-users-control`
    );
  }

  getAllActives() {
    return super.getWhere('active', '==', true);
  }
}

@Injectable({
  providedIn: 'root'
})
export class LicenseUserControlService {
  constructor(protected db: AngularFirestore, private _user: UserCustomerService) {}

  LicenseControl(licenseControlId: string) {
    return new LicenseUserControlFactory(licenseControlId, this.db);
  }

  CustomerLicenseControl(customerId: string, licenseControlId: string) {
    return new LicenseUserControlCustomerFactory(customerId, licenseControlId, this.db);
  }

  public async addLicenseAllUsers(list: License[]) {
    const users = await this._user.factory().getAll();
    const listSet: Promise<void>[] = [];

    if (users && users.length) {
      for (const user of users) {
        if (user.licenseId) {
          const license = list.find(
            x => x.id === (user.licenseId === 'patient' ? 'Er0bKd0bxu2TKn4oBbfB' : user.licenseId)
          );
          if (license) {
            const licenseUserControl = new LicenseUserControl();
            licenseUserControl.id = user.id;
            licenseUserControl.activationDates.push(licenseUserControl.lastActivationDate);
            listSet.push(this.LicenseControl(user.licenseId).set(licenseUserControl));
          }
        }
      }
    }

    return Promise.all(listSet);
  }
}
