<div class="row">
  <div class="col-md-8">
    <span class="my-label" *ngIf="activity && activity.time">{{activity.time}}
      minuto{{activity.time > 1 ? 's' : ''}}</span>
    <div class="my-input pl-2">
      <span>{{zeroPad(minutes)}}</span>:<span>{{zeroPad(seconds)}}</span>:<span>{{zeroPad(millis)}}</span>
    </div>
  </div>

  <div class="col-md-4 buttons-margin">
    <div class="timer-buttons">
      <button class="btn btn-primary btn-circle btn-sm mr-1" type="button" size="small" (click)="start()"
        [disabled]="(chronometerControl && chronometerControl.isRunning) || (training && training?.executed)"
        [hidden]="isRunning" *ngIf="!endTime">
        <i class="fe-play"></i>
      </button>

      <button class="btn btn-primary btn-circle btn-sm mr-1" type="button" size="small" (click)="pause()"
        [disabled]="training && training?.executed" [hidden]="!isRunning" *ngIf="!endTime">
        <i class="fe-pause"></i>
      </button>

      <div class="btn btn-primary btn-circle cursor-default btn-sm mr-1" type="button" size="small" *ngIf="endTime">
        <i class="fe-check"></i>
      </div>

    </div>
  </div>
</div>
