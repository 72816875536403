<div [formGroup]="formGroup" class="my-form">
  <i class="{{ icon }} iconfy" *ngIf="icon"></i>

  <label *ngIf="type !== 'checkbox' && type !== 'switch' && type !== 'groupbox' && label" [for]="name">
    {{ label }} <span [hidden]="!f?.errors?.required" class="text-danger">*</span>
  </label>

  <!-- -------- TEXT, PASSWORD, DATES -------- -->
  <div class="d-flex justify-content-end">
    <input
      [ngClass]="{ 'icon-space': icon.length > 0 }"
      data-toggle="input-mask"
      [mask]="mask"
      [id]="id"
      *ngIf="
        type !== 'textarea' &&
        type !== 'html' &&
        type !== 'color' &&
        type !== 'select' &&
        type !== 'number' &&
        type !== 'switch' &&
        type !== 'checkbox' &&
        type !== 'groupbox'
      "
      [type]="type === 'daterange' || type === 'date' || (type === 'password' && viewPasswd) ? 'text' : type"
      (focusout)="textBlur($event)"
      class="form-control {{ textUppercase ? 'my-input-uppercase' : 'my-input' }}"
      [formControlName]="name"
      placeholder="{{ placeholder ? placeholder : 'Digite o ' + label }}"
      [class.is-invalid]="submitted && f.errors"
    />

    <a (click)="changeViewPasswd()" class="action-icon passwd-eye" *ngIf="type === 'password'">
      <i class="fe-eye" *ngIf="!viewPasswd" ngbTooltip="Visualizar senha"></i>
      <i class="fe-eye-off" *ngIf="viewPasswd" ngbTooltip="Esconder senha"></i>
    </a>

    <a class="action-icon calendar" (click)="selectDate()" *ngIf="type === 'date' || type === 'daterange'">
      <i class="fas fa-calendar-alt"></i>
    </a>
  </div>

  <!-- -------- number -------------------------------- -->
  <input
    *ngIf="type === 'number'"
    [formControlName]="name"
    type="number"
    min="0"
    [id]="id"
    [min]="minValue"
    placeholder="{{ placeholder ? placeholder : 'Digite o ' + label }}"
    class="form-control my-input"
    [step]="step"
    [ngClass]="{ 'icon-space': icon.length > 0 }"
    [step]="step"
    [class.is-invalid]="submitted && f.errors"
  />

  <!-- -------- color ---------------------------------------- -->
  <div *ngIf="type === 'color'" class="input-group">
    <input
      type="text"
      [id]="name"
      [formControlName]="name"
      [class.is-invalid]="submitted && f.errors"
      class="form-control my-input input-lg text-uppercase"
      [value]="componentcolor"
    />
    <span class="input-group-append">
      <span
        class="input-group-text colorpicker-input-addon colorpicker-border"
        [style.background]="componentcolor ? componentcolor : '#ffffff'"
        [(colorPicker)]="componentcolor"
        (colorPickerChange)="colorChange()"
        [cpPosition]="colorPosition"
        ><i></i
      ></span>
    </span>
  </div>

  <!-- -------- checkbox ---------------------------------------- -->
  <label *ngIf="type === 'checkbox'">&nbsp;</label>
  <div *ngIf="type === 'checkbox'" class="custom-control custom-checkbox">
    <input type="checkbox" [formControlName]="name" class="custom-control-input" [id]="id" />
    <label class="custom-control-label" [for]="id">{{ label }}</label>
  </div>

  <!-- -------- groupbox ---------------------------------------- -->
  <div *ngIf="type === 'groupbox'" class="col-12">
    <div class="col-12" *ngFor="let cb of items; index as i">
      <input
        type="{{ multiple ? 'checkbox' : 'radio' }}"
        class="custom-control-input"
        [checked]="isChecked(cb, f?.value)"
        [formControlName]="name"
        [id]="id + i"
        [value]="cb[selectId] ? cb[selectId] : cb"
      />
      <label
        [ngClass]="multiple ? 'custom-control-label' : 'custom-control-label my-radio'"
        [for]="name"
        class="cursor-pointer m-1"
        (click)="gbClick($event, cb)"
      >
        {{ cb[selectLabel] ? cb[selectLabel] : cb[selectId] ? cb[selectId] : cb }}
      </label>
    </div>
  </div>

  <!-- -------- switch ---------------------------------------- -->
  <label
    [for]="name"
    class="text-center p-0 m-0"
    [ngClass]="{ 'd-flex justify-content-center': alignCenter }"
    *ngIf="type === 'switch' && label"
  >
    {{ label }} <span [hidden]="!f?.errors?.required" class="text-danger">*</span>
  </label>
  <div class="col-12 mt-1" *ngIf="type === 'switch'">
    <div [ngClass]="{ 'd-flex justify-content-center': alignCenter }">
      <ui-switch
        class="vertical-middle"
        [disabled]="disabled"
        [defaultBoColor]="defaultBoColor"
        [formControlName]="name"
        [id]="name"
        [color]="color"
        checked
      ></ui-switch>
    </div>
    <div [ngClass]="{ 'd-flex justify-content-center': alignCenter }">
      <label class="text-center p-0 my-1" [for]="name">{{ f && f.value ? 'Sim' : 'Não' }}</label>
    </div>
  </div>

  <!-- ------- textarea ---------------------------------------- -->
  <textarea
    [autofocus]="true"
    *ngIf="type === 'textarea'"
    class="form-control my-textarea"
    [ngClass]="{ 'my_textarea-auto': autoHeight }"
    [formControlName]="name"
    [rows]="rows"
    placeholder="{{ placeholder ? placeholder : 'Digite o ' + label }}"
    [id]="id"
    [class.is-invalid]="submitted && f.errors"
  ></textarea>

  <!-- -------- select ---------------------------------------- -->
  <ng-select
    placeholder="{{ placeholder ? placeholder : 'Selecione o ' + label }}"
    (change)="emit('change', $event)"
    *ngIf="type === 'select'"
    [items]="items"
    [formControlName]="name"
    [id]="id"
    [addTag]="addCustomUser"
    [class.addItemSelect]="addSelect"
    [class.is-invalid]="submitted && f.errors"
    [multiple]="multiple"
    bindLabel="{{ selectLabel }}"
    [appendTo]="appendTo"
    notFoundText="{{ notFoundText }}"
    (open)="openSelectList()"
    bindValue="{{ tag ? (tagValue ? tagValue : selectLabel) : selectId }}"
    [groupBy]="groupBy"
    [groupValue]="groupValue"
    addTagText="{{ addTagText }}"
    clearAllText="{{ clearAllText }}"
    [class.large-select]="largeSelect"
    [class.more-than-one]="multiple && f.value?.length > 1"
    [clearable]="clearable"
  >
  </ng-select>

  <!-- <div *ngIf="type=='html'" class="input-group">
    <ckeditor [formControlName]="name" [id]="name" [editor]="Editor">
    </ckeditor>
  </div> -->

  <span class="font-13 text-muted">{{ ex }}</span>
  <div *ngIf="submitted" class="text-danger">
    <div *ngIf="f?.errors?.required">{{ label ? label + ' é' : 'É' }} obrigatório</div>
    <div *ngIf="f?.errors?.cpf">{{ label }} está incorreto</div>
    <div *ngIf="f?.errors?.cnpj">{{ label }} está incorreto</div>
    <div *ngIf="f?.errors?.pattern">{{ label }} com caracteres inválido</div>
    <div
      *ngIf="
        !f?.errors?.required && !f?.errors?.cpf && !f?.errors?.cnpj && !f?.errors?.pattern && f?.status === 'INVALID'
      "
    >
      Inválido
    </div>

    <div *ngIf="f?.errors?.max">Valor máximo {{ f.errors.max.max }}, atual {{ f.errors.max.actual }}</div>
    <div *ngIf="f?.errors?.min">Valor mínimo {{ f.errors.min.min }}, atual {{ f.errors.min.actual }}</div>
  </div>
</div>
