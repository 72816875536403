import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InputFormModule } from '@forms/component/input-form.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { UIModule } from '@shared/ui/ui.module';
import { PipeModule } from 'src/app/core/pipes/pipe.module';

import { PatientSuspensionJustificationCreateComponent } from './patient-suspension-justification-create/patient-suspension-justification-create.component';

@NgModule({
  declarations: [PatientSuspensionJustificationCreateComponent],
  exports: [PatientSuspensionJustificationCreateComponent],
  entryComponents: [PatientSuspensionJustificationCreateComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputFormModule,
    NgbDropdownModule,
    NgbPaginationModule,
    PipeModule,
    UIModule
  ]
})
export class SuspensionJustificationsModule { }
