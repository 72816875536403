import { Component, HostListener, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ChatType } from '@enums/chat.enum';
import { UserType } from '@enums/user-type';
import { Chat } from '@models/chat';
import { JobTitle } from '@models/job-title';
import { User } from '@models/user';
import { ChatMessagesNotRead } from '@pages/chats/chat-user/chat-user.component';
import { AuthSessionService } from '@services/auth-session.service';
import { ChatService } from '@services/chat.service';
import { ChatMessageService } from '@services/chat-message.service';
import { EventParentToChildService } from '@services/event-parent-to-child.service';
import { TitleTopBarService } from '@services/title-top-bar.service';
import { UserCustomerService } from '@services/user-customer.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CustomCapitalizePipe } from 'src/app/core/pipes/custom-capitalize.pipe';
import { DateChatPipe } from 'src/app/core/pipes/date-chat.pipe';
import { environment } from 'src/environments/environment';

import { JobTitleService } from '../../core/services/job-title.service';

class NotificationItem {
  chatId: string;
  userName: string;
  text: string;
  subText: string;
  icon: string;
  bgColor: string;
  redirectTo: string;
}

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, OnDestroy {
  user: User;
  users: User[] = [];
  jobTitle: JobTitle = null;
  title: string;
  subtitle: string;
  notificationItems = [];
  subscriptions: Subscription[] = [];
  version = environment.appVersion;
  notReads: ChatMessagesNotRead = {};
  showTitle = true;

  constructor(
    private router: Router,
    private _authSession: AuthSessionService,
    private _userCustomer: UserCustomerService,
    private route: ActivatedRoute,
    private _titleTopBar: TitleTopBarService,
    private _jobTitle: JobTitleService,
    private _chat: ChatService,
    private _messages: ChatMessageService,
    private customCapitalize: CustomCapitalizePipe,
    private dateChat: DateChatPipe,
    private _emitEvents: EventParentToChildService,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.getUsers();
    this.user = this._authSession.getLoggedUser();
    if (this.user.type !== UserType.MASTER) {
      this.getChatsAndMessagesNotReads();
    }
    this.subscriptions.push(
      this._authSession.loggedUser.subscribe(user => {
        this.user = user;
      })
    );
    if (this.user.jobTitleId) {
      this.loadJobTitle();
    }
    this.subscribeNavigationEnd();
    this.changeTitle();
  }

  getUsers() {
    try {
      this._userCustomer.getAsyncAll().subscribe(docs => {
        for (const chat of docs) {
          const { data, type } = chat;
          if (type === 'added') {
            this.users.unshift(data);
          } else if (type === 'modified') {
            const pos = this.users.findIndex(c => c.id === data.id);
            if (pos > -1) {
              this.users.splice(pos, 1);
            }
            this.users.unshift(data);
          } else {
            const pos = this.users.findIndex(c => c.id === data.id);
            if (pos > -1) {
              this.users.splice(pos, 1);
            }
          }
        }
      });
    } catch (e) {}
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  changeTitle() {
    let child = this.route.firstChild;
    while (child.firstChild) {
      child = child.firstChild;
    }
    if (child.snapshot.data.topbarTitle) {
      this._titleTopBar.update(child.snapshot.data.topbarTitle);
      if (child.snapshot.data.mobile) {
        const width = window.innerWidth;
        if (width < 600) {
          this.showTitle = false;
        }
      } else {
        this.showTitle = true;
      }
    } else {
      this._titleTopBar.update(null);
    }
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onResize(event) {
    const width = window.innerWidth;
    let child = this.route.firstChild;
    while (child.firstChild) {
      child = child.firstChild;
    }
    if (child.snapshot.data.mobile) {
      if (width < 600) {
        this.showTitle = false;
      } else {
        this.showTitle = true;
      }
    }
  }

  subscribeNavigationEnd() {
    this.subscriptions.push(
      this._titleTopBar.change.subscribe(title => {
        this.title = title;
      })
    );

    this.subscriptions.push(
      this._titleTopBar.changeSub.subscribe(subtitle => {
        this.subtitle = subtitle;
      })
    );

    this.subscriptions.push(
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
        this.changeTitle();
      })
    );
  }

  loadJobTitle() {
    try {
      this._jobTitle.getAsyncById(this.user.jobTitleId).subscribe(doc => {
        this.ngZone.run(() => {
          if (doc) {
            this.jobTitle = doc;
          }
        });
      });
    } catch (e) {}
  }

  getChatsAndMessagesNotReads() {
    this._chat.getAsyncByUser().subscribe(docs => {
      this.ngZone.run(() => {
        for (const chat of docs) {
          const { type, data } = chat;
          if (type === 'added') {
            this.getNotReadMessages(data);
          } else if (type === 'removed') {
            const posItem = this.notificationItems.findIndex(ni => ni.chatId && ni.chatId === data.id);
            if (posItem > -1) {
              this.notificationItems.splice(posItem, 1);
            }
          }
        }
      });
    });
  }

  userName(id: string) {
    const user = this.users.find(u => u.id === id);
    return user ? user.name : '?';
  }

  userFirstName(id: string) {
    const user = this.users.find(u => u.id === id);
    const name = user ? user.name.split(' ')[0] : '';
    return this.customCapitalize.transform(name);
  }

  getNotReadMessages(chat: Chat) {
    if (!this.notReads[chat.id]) {
      this.notReads[chat.id] = [];
    }
    this._messages
      .Chat(chat.id)
      .getAsyncAllNotRead(this.user.id)
      .subscribe(messages => {
        for (const message of messages) {
          const { data, type } = message;
          if (type === 'added') {
            if (data.userId !== this.user.id) {
              if (!this.notReads[chat.id].find(m => m.id === data.id)) {
                this.notReads[chat.id].unshift(data);
              }
              const itemIndex = this.notificationItems.findIndex(item => item.chatId && item.chatId === chat.id);
              if (itemIndex >= 0) {
                const countNotRead = this.notReads[chat.id].length;
                this.notificationItems[itemIndex].text =
                  chat.type === ChatType.DEFAULT
                    ? `${this.userFirstName(data.userId)} enviou ${countNotRead} menssage${
                        countNotRead > 1 ? 'ns' : 'm'
                      }`
                    : `${chat.name} tem ${countNotRead} mensage${countNotRead > 1 ? 'ns' : 'm'} não lida${
                        countNotRead > 1 ? 's' : ''
                      }`;
                this.notificationItems[itemIndex].subText = `${this.dateChat.transform(data.createdAt)}`;
              } else {
                const countNotRead = this.notReads[chat.id].length;
                const item: NotificationItem = {
                  ...new NotificationItem(),
                  chatId: chat.id,
                  userName: chat.type === ChatType.DEFAULT ? this.userName(data.userId) : chat.name,
                  text:
                    chat.type === ChatType.DEFAULT
                      ? `${this.userFirstName(data.userId)} enviou ${countNotRead} mensage${
                          countNotRead > 1 ? 'ns' : 'm'
                        }`
                      : `${chat.name} tem ${countNotRead} mensage${countNotRead > 1 ? 'ns' : 'm'} não lida${
                          countNotRead > 1 ? 's' : ''
                        }`,
                  subText: `${this.dateChat.transform(data.createdAt)}`,
                  redirectTo: '/chat-user/contacts'
                };
                this.notificationItems.unshift(item);
              }
            }
          } else if (type === 'removed') {
            const pos = this.notReads[chat.id].findIndex(c => c.id === data.id);
            if (pos > -1) {
              this.notReads[chat.id].splice(pos, 1);
              const itemIndex = this.notificationItems.findIndex(item => item.chatId === chat.id);
              const lastData = this.notReads[chat.id].length
                ? this.notReads[chat.id][this.notReads[chat.id].length - 1]
                : null;
              if (lastData) {
                this.notificationItems[itemIndex].text = `${this.userFirstName(lastData.userId)} enviou ${
                  this.notReads[chat.id].length
                } menssage${this.notReads[chat.id].length > 1 ? 'ns' : 'm'}`;
                this.notificationItems[itemIndex].subText = `${this.dateChat.transform(lastData.createdAt)}`;
              } else {
                this.notificationItems.splice(itemIndex, 1);
              }
            }
          }
        }
      });
  }

  clearAllNotificationItems() {
    for (const item of this.notificationItems) {
      if (item.chatId) {
        if (this.notReads[item.chatId]?.length) {
          this._messages.Chat(item.chatId).setIsReadByList(this.notReads[item.chatId]);
        }
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clickNotificationItem(item: any) {
    if (item.chatId) {
      if (window.location.href.includes('chat-user/contacts')) {
        this._emitEvents.broadcast({ name: 'emitFromTopbar', value: item.chatId });
      } else {
        this.router.navigate([item.redirectTo, item.chatId]);
      }
    }
  }

  async logout() {
    await this._authSession.logout();

    this.router.navigate(['/login']);
  }
}
