import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserType } from '@enums/user-type';
import { Chat } from '@models/chat';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { AuthSessionService } from './auth-session.service';
import { FirebaseAbstract, IDocumentObservable } from './firebase.abstract';

interface IResponseChats {
  lastDocVisible;
  docs: IDocumentObservable<Chat>[];
}

interface IResponseMoreChats {
  lastDocVisible;
  docs: Chat[];
}

class ChatFactory extends FirebaseAbstract<Chat> {
  constructor(protected db: AngularFirestore) {
    super(db, `/projects/${environment.project}/customers/${AuthSessionService.user.projectAccess[0].customer}/chats`);
  }

  getByUserId(id: string) {
    return super.getWhere('participants', 'array-contains', id, 'createdAt', 'desc');
  }

  async getByUserIdAndLogged(id: string) {
    const loggedUserId = AuthSessionService.user.id;
    const docs = await super.getWhere('participants', 'array-contains-any', [loggedUserId, id], 'createdAt', 'desc');

    return docs?.length
      ? docs.filter(chat => chat.participants.includes(loggedUserId) && chat.participants.includes(id))
      : [];
  }

  async getByUserIds(ids: string[]) {
    const query: Promise<Chat[]>[] = [];
    let chats: Chat[] = [];
    for (const id of ids) {
      query.push(this.getByUserIdAndLogged(id));
    }
    await Promise.all(query).then(docs => {
      for (const doc of docs) {
        if (doc?.length) {
          chats = chats.concat(doc);
        }
      }
    });
    return chats;
  }

  getAsyncByUser() {
    const id = AuthSessionService.user.id;
    return super.getAsyncWhere('participants', 'array-contains', id, 'createdAt', 'desc');
  }

  getAsyncByUserId(id: string) {
    return super.getAsyncWhere('participants', 'array-contains', id, 'createdAt', 'desc');
  }

  getByListId(list: string[]) {
    const query: Promise<Chat>[] = [];
    list.forEach(id => {
      query.push(super.getById(id));
    });
    return Promise.all(query);
  }

  getChats(): Observable<IResponseChats> {
    const userId = AuthSessionService.user.id;
    return this.db
      .collection<Chat>(this.collectionName, ref =>
        (AuthSessionService.user.type !== UserType.ADMINISTRATOR
          ? ref.where('participants', 'array-contains', userId)
          : ref
        )
          .orderBy('lastMessage.createdAt', 'desc')
          .limit(10)
      )
      .stateChanges()
      .pipe(
        map(data => ({
          lastDocVisible: data[data.length - 1]?.payload.doc,
          docs: data
            .map(({ type, payload: { doc, newIndex, oldIndex } }) => ({
              type,
              newIndex,
              oldIndex,
              data: this.toObject(doc)
            }))
            .reverse()
        }))
      );
  }

  getUpdateChats(): Observable<IResponseChats> {
    const userId = AuthSessionService.user.id;
    return this.db
      .collection<Chat>(this.collectionName, ref =>
        (AuthSessionService.user.type !== UserType.ADMINISTRATOR
          ? ref.where('participants', 'array-contains', userId)
          : ref
        ).orderBy('lastMessage.createdAt', 'desc')
      )
      .stateChanges()
      .pipe(
        map(data => ({
          lastDocVisible: data[data.length - 1]?.payload.doc,
          docs: data
            .map(({ type, payload: { doc, newIndex, oldIndex } }) => ({
              type,
              newIndex,
              oldIndex,
              data: this.toObject(doc)
            }))
            .reverse()
        }))
      );
  }

  async getMoreChats(startAfter): Promise<IResponseMoreChats> {
    const userId = AuthSessionService.user.id;

    const { docs, size } =
      AuthSessionService.user.type === UserType.ADMINISTRATOR
        ? await this.collection().orderBy('lastMessage.createdAt', 'desc').startAt(startAfter).limit(10).get()
        : await this.collection()
            .where('participants', 'array-contains', userId)
            .orderBy('lastMessage.createdAt', 'desc')
            .startAfter(startAfter)
            .limit(10)
            .get();

    if (size === 0) {
      return null;
    }

    return {
      lastDocVisible: docs[docs.length - 1],
      docs: docs.map(doc => this.toObject(doc))
    };
  }
}

class ChatExternalFactory extends FirebaseAbstract<Chat> {
  constructor(customerId: string, protected db: AngularFirestore) {
    super(db, `/projects/${environment.project}/customers/${customerId}/chats`);
  }

  getByCPF(cpf: string) {
    return super.getWhere('cpf', '==', cpf);
  }

  public getByListId(list: string[]) {
    const query: Promise<Chat>[] = [];
    list.forEach(id => {
      query.push(super.getById(id));
    });
    return Promise.all(query);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  constructor(protected db: AngularFirestore) {}

  factory() {
    return new ChatFactory(this.db);
  }

  externalFactory(customerId: string) {
    return new ChatExternalFactory(customerId, this.db);
  }

  add(data: Chat) {
    return this.factory().add(data);
  }

  update(data: Partial<Chat>) {
    return this.factory().update(data);
  }

  updateParticipants(data: Partial<Chat>) {
    return this.factory().update({ id: data.id, participants: data.participants });
  }

  set(data: Chat) {
    return this.factory().set(data);
  }

  getById(id: string) {
    return this.factory().getById(id);
  }

  getByUserId(id: string) {
    return this.factory().getByUserId(id);
  }

  getByUserIds(ids: string[]) {
    return this.factory().getByUserIds(ids);
  }

  getAsyncByUser() {
    return this.factory().getAsyncByUser();
  }

  getAsyncByUserId(id: string) {
    return this.factory().getAsyncByUserId(id);
  }

  getByListId(list: string[]) {
    return this.factory().getByListId(list);
  }

  getAll() {
    return this.factory().getAll();
  }

  getChats() {
    return this.factory().getChats();
  }

  getUpdateChats() {
    return this.factory().getUpdateChats();
  }

  getMoreChats(startAfter) {
    return this.factory().getMoreChats(startAfter);
  }

  public async setTypeAll() {
    const customers = ['2XlFkP2jOjkRfM8iGNnV', 'qP2ZuMLDMN2nSrNDyLyZ'];
    const updates: Promise<void>[] = [];

    for (const customer of customers) {
      try {
        const list = await this.externalFactory(customer).getAll();
        if (list?.length) {
          for (const chat of list) {
            if (!chat.type) {
              updates.push(this.externalFactory(customer).update({ id: chat.id, type: 'default' }));
            }
          }
        }
      } catch (error) {}
    }

    return Promise.all(updates);
  }
}
