import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { LicenseControl } from '@models/license-control';
import { LicenseUserControl } from '@models/license-user-control';
import { environment } from 'src/environments/environment';

import { AuthSessionService } from './auth-session.service';
import { CustomerService } from './customer.service';
import { FirebaseAbstract } from './firebase.abstract';
import { LicenseUserControlService } from './license-user-control.service';

class LicenseControlFactory extends FirebaseAbstract<LicenseControl> {
  constructor(protected db: AngularFirestore) {
    super(
      db,
      `/projects/${environment.project}/customers/${AuthSessionService.user.projectAccess[0].customer}/license-control`
    );
  }
}
class LicenseControlExternalFactory extends FirebaseAbstract<LicenseControl> {
  constructor(customerId: string, protected db: AngularFirestore) {
    super(db, `/projects/${environment.project}/customers/${customerId}/license-control`);
  }
}

@Injectable({
  providedIn: 'root'
})
export class LicenseControlService {
  constructor(private _licenseUserControl: LicenseUserControlService, protected db: AngularFirestore) {}

  factory() {
    return new LicenseControlFactory(this.db);
  }

  externalFactory(customerId: string) {
    return new LicenseControlExternalFactory(customerId, this.db);
  }

  getAll() {
    return this.factory().getAll();
  }

  getAllActives() {
    return this.factory().getWhereMany([{ field: 'active', operator: '==', value: true }]);
  }

  getByCustomerId(customerId) {
    return this.factory().getAll();
  }

  getById(id) {
    return this.factory().getById(id);
  }

  getByIds(ids: string[]) {
    const query: Promise<LicenseControl>[] = [];
    for (const id of ids) {
      query.push(this.getById(id));
    }
    return Promise.all(query);
  }

  getAsyncById(id) {
    return this.factory().getAsyncById(id);
  }

  public async getLicenseUsersControl(id): Promise<LicenseUserControl[]> {
    return this._licenseUserControl.LicenseControl(id).getAll();
  }

  add(data: LicenseControl) {
    return this.factory().add(data);
  }

  set(data: LicenseControl, customerId: string = null) {
    return this.factory().set(data);
  }

  update(data: Partial<LicenseControl>) {
    return this.factory().update(data);
  }

  save(data: LicenseControl) {
    return this.factory().save(data);
  }

  async isLimitAddById(licenseId: string): Promise<boolean> {
    const license = await this.factory().getById(licenseId);
    if (license) {
      const licensesActives = await this._licenseUserControl.LicenseControl(licenseId).getAllActives();
      const limit = license.quantity;
      const actives = licensesActives ? licensesActives.length : 0;
      return limit - actives > 0;
    }
    return false;
  }

  public async countActivesById(licenseId: string) {
    const licenseActives = await this._licenseUserControl.LicenseControl(licenseId).getAllActives();
    return licenseActives ? { id: licenseId, count: licenseActives.length } : { id: licenseId, count: 0 };
  }

  public async listCountActivesByList(list: LicenseControl[]) {
    const query: Promise<{ id: string; count: number }>[] = [];
    list.forEach(l => {
      query.push(this.countActivesById(l.id));
    });
    return Promise.all(query);
  }
}
