import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FullCalendarModule } from '@fullcalendar/angular';
import { PagesRoutingModule } from '@pages/pages-routing.module';
import { PatientSuspensionJustificationCreateComponent } from '@pages/patients/suspension-justifications/patient-suspension-justification-create/patient-suspension-justification-create.component';
import { SuspensionJustificationsModule } from '@pages/patients/suspension-justifications/suspension-justifications.module';
import * as FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import * as FilePondPluginImageResize from 'filepond-plugin-image-resize';
import { FilePondModule, registerPlugin } from 'ngx-filepond';
import { NgxImageCompressService } from 'ngx-image-compress';
import { QuillModule } from 'ngx-quill';
import { environment } from 'src/environments/environment';

import { AppComponent } from './app.component';
import { ChronometerComponent } from './component/chronometer/chronometer.component';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { UrlInterceptor } from './core/interceptors/url.interceptor';
import { CustomCapitalizePipe } from './core/pipes/custom-capitalize.pipe';
import { LayoutsModule } from './layouts/layouts.module';

registerPlugin(FilePondPluginFileValidateType, FilePondPluginImageResize);
registerLocaleData(localePt, 'pt');
@NgModule({
  declarations: [AppComponent],
  entryComponents: [PatientSuspensionJustificationCreateComponent, ChronometerComponent],
  imports: [
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FullCalendarModule,
    LayoutsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    FilePondModule,
    PagesRoutingModule,
    SuspensionJustificationsModule,
    QuillModule.forRoot()
  ],
  providers: [
    CustomCapitalizePipe,
    NgxImageCompressService,
    { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
