// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: require('../../package.json').version + '-dev',
  apiUrl: 'https://us-central1-metodoneurostimulus-200818.cloudfunctions.net/api',
  // apiUrl: 'https://localhost:5001/api',
  // apiUrl: 'http://localhost:5001/metodoneurostimulus-200818/us-central1/api',
  siteUrl: 'https://metodoneurostimulus-200818.web.app',
  apiKey: '1784ae03-4755-4538-9e51-28cacf8e921a',
  storage: {
    url: 'https://operativenode.azurewebsites.net/api',
    bucket: 'operative-storage',
    endpoint: 'nyc3.digitaloceanspaces.com'
  },
  firebase: {
    apiKey: 'AIzaSyDvKE5uflf4Z7JHaCExsgYXSctFim_NtlU',
    authDomain: 'metodoneurostimulus-200818.firebaseapp.com',
    databaseURL: 'https://metodoneurostimulus-200818.firebaseio.com',
    projectId: 'metodoneurostimulus-200818',
    storageBucket: 'metodoneurostimulus-200818.appspot.com',
    messagingSenderId: '775852745019',
    appId: '1:775852745019:web:aba3d31cdf208ce6ce171e',
    measurementId: 'G-NC9RK3P1L4'
  },
  ownerName: 'Método NeuroStimulus',
  address: {
    street: 'Rua Maria da Glória',
    number: '216',
    district: 'Santa Helena',
    city: 'Presidente Prudente',
    state: 'SP',
    cep: '19015-650'
  },
  project: 'fisio-inteligente'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
