import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbTimepickerModule
} from '@ng-bootstrap/ng-bootstrap';

import { CountToDirective } from './count-to.directive';
import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { PreloaderComponent } from './preloader/preloader.component';

@NgModule({
  declarations: [CountToDirective, PreloaderComponent, PagetitleComponent],
  imports: [CommonModule, FormsModule, NgbCollapseModule, NgbDatepickerModule, NgbTimepickerModule, NgbDropdownModule],
  exports: [CountToDirective, PreloaderComponent, PagetitleComponent]
})
export class UIModule {}
