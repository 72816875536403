import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthAdminGuard } from '@guards/auth.admin.guard';
import { AuthMasterAdminGuard } from '@guards/auth.master.admin.guard';
import { AuthMasterGuard } from '@guards/auth.master.guard';
import { AuthRegisterGuard } from '@guards/auth.register.guard';
import { AuthUserAcceptUseTermsGuard } from '@guards/auth.user-accept-use-terms.guard';

import { AuthGuard } from '../core/guards/auth.guard';
import { AuthUserFirstGuard } from '../core/guards/auth.user-first-access.guard';
import { NotAuthGuard } from '../core/guards/not-auth.guard';
import { LayoutComponent } from '../layouts/layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'register',
    canActivate: [AuthRegisterGuard],
    loadChildren: () => import('./register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'user-first-access',
    component: LayoutComponent,
    canActivate: [AuthUserFirstGuard],
    loadChildren: () => import('./users-first-access/users-first-access.module').then(m => m.UsersFirstAccessModule)
  },
  {
    path: 'user-accept-use-terms',
    component: LayoutComponent,
    canActivate: [AuthUserAcceptUseTermsGuard],
    loadChildren: () =>
      import('./users-accept-use-terms/users-accept-use-terms.module').then(m => m.UsersAcceptUseTermsModule)
  },
  {
    path: 'master',
    component: LayoutComponent,
    canActivateChild: [AuthMasterGuard],
    loadChildren: () => import('./master/master.module').then(m => m.MasterModule)
  },
  {
    path: 'admin',
    component: LayoutComponent,
    canActivateChild: [AuthAdminGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: '',
    component: LayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'license',
        loadChildren: () => import('./licenses/licenses.module').then(m => m.LicensesModule)
      },
      {
        path: 'payments',
        loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule)
      },
      {
        path: 'schedule',
        loadChildren: () => import('./schedules/schedules.module').then(m => m.SchedulesModule)
      },
      {
        path: 'banked-hours',
        loadChildren: () => import('./banked-hours/banked-hours.module').then(m => m.BankedHoursModule)
      },
      {
        path: 'user',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'patient',
        loadChildren: () => import('./patients/patients.module').then(m => m.PatientsModule)
      },
      {
        path: 'patient-lead',
        loadChildren: () => import('./patient-leads/patient-leads.module').then(m => m.PatientLeadsModule)
      },
      {
        path: 'therapist',
        loadChildren: () => import('./therapists/therapists.module').then(m => m.TherapistsModule)
      },
      {
        path: 'tutorials',
        loadChildren: () => import('./tutorials/tutorials.module').then(m => m.TutorialsModule)
      },
      {
        path: 'room',
        loadChildren: () => import('./rooms/rooms.module').then(m => m.RoomsModule)
      },
      // {
      //   path: 'specialty',
      //   loadChildren: () => import('./specialties/specialties.module').then(m => m.SpecialtiesModule)
      // },
      {
        path: 'scheduling-type',
        loadChildren: () => import('./scheduling-types/scheduling-types.module').then(m => m.SchedulingTypesModule)
      },
      {
        path: 'check-list',
        loadChildren: () => import('./check-list/check-list.module').then(m => m.CheckListModule)
      },
      {
        path: 'semiology',
        loadChildren: () => import('./semiology/semiology.module').then(m => m.SemiologyModule)
      },
      {
        path: 'settings',
        canActivate: [AuthMasterAdminGuard],
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'anamnese',
        loadChildren: () => import('./anamnese/anamnese.module').then(m => m.AnamneseModule)
      },
      {
        path: 'evaluation',
        loadChildren: () => import('./evaluation/evaluation.module').then(m => m.EvaluationModule)
      },
      {
        path: 'treatment',
        loadChildren: () => import('./treatment-plan/treatment-plan.module').then(m => m.TreatmentPlanModule)
      },
      {
        path: 'evolution',
        loadChildren: () => import('./evolution/evolution.module').then(m => m.EvolutionModule)
      },
      {
        path: 'doctor',
        loadChildren: () => import('./doctors/doctors.module').then(m => m.DoctorsModule)
      },
      {
        path: 'invoice',
        loadChildren: () => import('./invoices-adm/invoices-adm.module').then(m => m.InvoicesAdmModule)
      },
      {
        path: 'chat-user',
        loadChildren: () => import('./chats/chats.module').then(m => m.ChatsModule)
      }
    ]
  },
  {
    path: '',
    canActivateChild: [NotAuthGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '**',
    loadChildren: () => import('./errors/404/error-404.module').then(m => m.Error404Module)
  }
];

@NgModule({
  // imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', enableTracing: true })],
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule]
})
export class PagesRoutingModule {}
