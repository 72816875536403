import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { CustomCapitalizePipe } from '../core/pipes/custom-capitalize.pipe';
import { DateChatPipe } from '../core/pipes/date-chat.pipe';
import { PipeModule } from '../core/pipes/pipe.module';
import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';

@NgModule({
  declarations: [LayoutComponent, SidebarComponent, TopbarComponent],
  imports: [CommonModule, RouterModule, NgbDropdownModule, NgbTooltipModule, PipeModule],
  providers: [CustomCapitalizePipe, DateChatPipe]
})
export class LayoutsModule {}
