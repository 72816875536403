import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateChat'
})
export class DateChatPipe implements PipeTransform {
  transform(value: Date): string {
    const date = moment(value);
    const dateYesteroday = moment().add(-1, 'days').startOf('day');
    const dateToday = moment().startOf('day');

    if (date.isAfter(dateToday)) {
      return date.format('HH:mm');
    } else if (date.isAfter(dateYesteroday)) {
      return `Ontem ${date.format('HH:mm')}`;
    }

    return date.format('DD/MM/YY HH:MM');
  }
}
