import { Pipe, PipeTransform } from '@angular/core';
import { translateNameType } from '@utils/functions-utils';

@Pipe({
  name: 'translateNameTypePipe'
})
export class TranslateNameTypePipe implements PipeTransform {
  transform(name: string): string {
    if (!(name || '').length) {
      return '';
    }

    return translateNameType(name);
  }
}
