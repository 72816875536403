import { OnInit, Component, Output, EventEmitter, Input } from '@angular/core';
import { Activity, Preparation, TrainingSetupNeuroStimulus } from '../../core/models/training-setup-neuro-stimulus';
import { ChronometerControl } from '../../core/models/chronometer-control';

interface IChronometer {
  hours: number;
  minutes: number;
  seconds: number;
  millis: number;
}

@Component({
  selector: 'app-chronometer',
  templateUrl: './chronometer.component.html',
  styleUrls: ['./chronometer.component.scss']
})
export class ChronometerComponent implements OnInit {
  @Input() chronometerControl: ChronometerControl = null;
  @Input() activity: Preparation | Activity = null;
  @Input() training: TrainingSetupNeuroStimulus = null;
  hours = 0;
  minutes = 0;
  seconds = 0;
  millis = 0;
  isRunning = false;
  endTime = false;

  tempo = 10;
  cron;

  @Output() onpause = new EventEmitter<IChronometer>();
  @Output() onstop = new EventEmitter<IChronometer>();
  @Output() setValue = new EventEmitter<IChronometer>();

  ngOnInit() {
    if (this.activity) {
      this.endTime = this.activity.checked;
      if (this.endTime) {
        this.minutes = this.activity.time;
      }
    }
  }

  start() {
    if (!this.isRunning) {
      this.isRunning = true;
      if (this.chronometerControl) this.chronometerControl.isRunning = this.isRunning;
      this.cron = setInterval(() => {
        this.timer();
      }, this.tempo);
    }
  }

  pause() {
    clearInterval(this.cron);
    this.isRunning = false;
    if (this.chronometerControl) this.chronometerControl.isRunning = this.isRunning;

    this.onpause.emit({
      hours: this.hours,
      minutes: this.minutes,
      seconds: this.seconds,
      millis: this.millis
    });
  }

  stop() {
    clearInterval(this.cron);
    this.hours = 0;
    this.minutes = 0;
    this.seconds = 0;

    this.millis = 0;
    this.isRunning = false;
    if (this.chronometerControl) this.chronometerControl.isRunning = this.isRunning;

    this.onstop.emit({
      hours: this.hours,
      minutes: this.minutes,
      seconds: this.seconds,
      millis: this.millis
    });
  }
  set() {
    this.pause();
    this.setValue.emit({
      hours: this.hours,
      minutes: this.minutes,
      seconds: this.seconds,
      millis: this.millis
    });
  }
  private timer() {
    this.millis += 1;
    if (this.millis === 99) {
      this.millis = 0;
      this.seconds += 1;

      if (this.seconds === 59) {
        this.seconds = 0;
        this.minutes += 1;

        if (this.minutes === 59) {
          this.minutes = 0;
          this.hours += 1;
        }
      }
    }

    if (this.minutes === this.activity.time) {
      this.endTime = true;
      if (this.activity) {
        this.activity.checked = true;
      }
      this.pause();
    }
  }

  zeroPad(value) {
    return value < 10 ? `0${value}` : value;
  }
}
